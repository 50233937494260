import { useState } from 'react';
import { useSelector } from 'react-redux';

import { isRegClose } from '@/utils/common';
import logo from '@/assets/images/main-logo.svg';
import Formats from '@/components/Formats/Formats';
import Container from '@/components/App/Container/Container';
import { getFormatsStore, getSelectedCity } from '@/store/slices/app';
import Online2021Popup from '@/components/Popups/Online2021Popup/Online2021Popup';

import styles from './SlotCost.module.scss';


const SlotCost = ({title, desc, instructionList, awardList, awardDesc, isOnline, isOnline2021 = false}) => {
  const formats = useSelector(getFormatsStore);
  const selectedCity = useSelector(getSelectedCity);
  const [isOpenPopup, setOpenPop] = useState(false);
  // const [cityId, setCityId] = useState({
  //   name: `online`,
  //   id: `999998`,
  // });
  //
  // useEffect(() => {
  //   if (city) {
  //     const foundCity = cities.find((el) => el.name === city);
  //
  //     if (foundCity) setCityId(foundCity);
  //   }
  // }, [city]);

  return (
    <Container>
      <div className={`${styles.top} ${isOnline && styles.topOnline} ${isOnline2021 && styles.topOnline2021}`} id="formats">
        <h2 className={styles.topTitle} id="tt">{title}</h2>
        <p className={styles.topDesc}>{desc}</p>
        <ul className={styles.topList}>
          {instructionList.map(item => (
            <li className={styles.topListItem} key={item.id}>
              {item.text}
            </li>
          ))}
        </ul>
        { isOnline2021 &&
        <div className={styles.bottomOnline2021}>
          <p className={`${styles.topDesc} ${styles.online2021Desc}`}>2 октября тебя ждет не только выдача пакетов участника. В этот день ты можешь принять участие в дружеском ЗаБеге!</p>
          <button className={styles.btn} type="button" onClick={() => setOpenPop(true)}>Бежать с единомышленниками</button>
        </div>
        }
      </div>

      {(isOnline && selectedCity && !isRegClose(selectedCity)) &&
        <div className={`${styles.bottom} ${isOnline && styles.bottomOnline}`}>
          <div className={styles.buy}>
            <img className={styles.logo} src={logo} width="94" height="45" alt="ЗаБег"/>
            <p className={styles.price}>{formats && formats[0].price} Р</p>
            <a className={`${styles.linkBuy} ${selectedCity && isRegClose(selectedCity) ? styles.linkBuyDisabled : ''}`} href={process.env.REACT_APP_API + `/calendar/${selectedCity?.event_public_id}/${selectedCity?.city?.id}`}>
              {selectedCity && isRegClose(selectedCity) ? 'Продажи закрыты' : 'Купить'}
            </a>
          </div>
          <div className={styles.award}>
            <h2 className={styles.bottomTitle}>Достойная награда</h2>
            <p className={styles.bottomText}>
              {awardDesc}
            </p>
            <ol className={styles.bottomList}>
              {awardList.map(item => (
                <li className={styles.bottomListItem} key={item.id}>{item.text}</li>
              ))}
            </ol>
            <p className={`${styles.bottomText} ${styles.bottomTextDesktop}`}>
              Получить медаль на месте можно после того как будет предъявлен результат.
            </p>
            <div className={styles.awardBottom}>
              <p className={styles.bottomText}>
                *Футболку участника ты можешь оплатить дополнительно.
              </p>
              <p className={styles.bottomText}>
                **Пакет участника будет доставлен в течение одного месяца после регистрации.
              </p>
            </div>
          </div>
          <div className={styles.tShirt}>
            <div className={styles.tShirtWrap}>
              <p className={`${styles.bottomText} ${styles.bottomTextTShirt}`}>Футболку участника ты можешь оплатить
                дополнительно.</p>
              <a className={styles.linkBuyTShirt} href="/">Купить</a>
            </div>
          </div>
        </div>
      }
      { (!isOnline && !isOnline2021) && <Formats formats={formats}/> }
      { isOpenPopup && <Online2021Popup handleStatePopup={setOpenPop} /> }

    </Container>
  );
};

export default SlotCost;
