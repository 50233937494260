import 'swiper/swiper.scss';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/navigation/navigation.scss';

import photo1 from '@/assets/images/gallary/gallary1.jpg';
import photo2 from '@/assets/images/gallary/gallary2.jpg';
import photo3 from '@/assets/images/gallary/gallary3.jpg';
import photo4 from '@/assets/images/gallary/gallary4.jpg';
import photo5 from '@/assets/images/gallary/gallary5.jpg';
import photo6 from '@/assets/images/gallary/gallary6.jpg';
import photo7 from '@/assets/images/gallary/gallary7.jpg';
import photo8 from '@/assets/images/gallary/gallary8.jpg';

import styles from './YourFriends.module.scss';


// Установка модулей Swiper
SwiperCore.use([Navigation]);

const photoFriendsArr = [
  {
    id: 0,
    photo: photo1,
  },
  {
    id: 1,
    photo: photo2,
  },
  {
    id: 2,
    photo: photo3,
  },
  {
    id: 3,
    photo: photo4,
  },
  {
    id: 4,
    photo: photo5,
  },
  {
    id: 5,
    photo: photo6,
  },
  {
    id: 6,
    photo: photo7,
  },
  {
    id: 7,
    photo: photo8,
  },
];

const YourFriends = () => {
  const sliderParams = {
    slidesPerView: 'auto',
    navigation: {
      prevEl: `.${styles.sliderNavPrev}`,
      nextEl: `.${styles.sliderNavNext}`,
    },
  };

  return (
    <div className={styles.yourFriends}>
      <h2 className={styles.title}>Твои друзья 2022</h2>
      <ul className={styles.imgList}>
        {photoFriendsArr.map(item => (
          <li className={styles.imgItem} key={item.id}>
            <img className={styles.img} src={item.photo} alt={`Фото №${item.id}`}/>
          </li>
        ))}
      </ul>

      <Swiper
        {...sliderParams}
        className={styles.slider}

      >
        <div className={styles.sliderNavPrev} />
        <div className={styles.sliderNavNext} />
        {photoFriendsArr.map(item => (
          <SwiperSlide className={styles.slide} key={item.id}>
            <img className={styles.img} src={item.photo} alt={`Фото №${item.id}`}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default YourFriends;
