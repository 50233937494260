import { createRef, useEffect, useState } from 'react';

import { getCurrentPrice, times } from '@/utils/common';

import styles from './PriceSteps.module.scss';


const PriceSteps = ({priceArr, currentPrice}) => {
  const currentPriceRef = createRef();
  const priceListRef = createRef();
  const pricePinRef = createRef();
  const priceMinRef = createRef();
  const priceMaxRef = createRef();

  const [progressBarWidth, setProgressBarWidth] = useState('');
  const [pricePinPosX, setPricePinPosX] = useState('');
  const [priceMinPosX, setPriceMinPosX] = useState('');
  const [priceMaxPosX, setPriceMaxPosX] = useState('');
  const [percent, setPercent] = useState(null);
  const isLastPrice = currentPrice === priceArr[priceArr.length - 1].price;
  const currentPriceDate = getCurrentPrice(priceArr)?.start_date;
  const nextPriceDate = !isLastPrice && currentPriceDate ? times.getNumberShortDate(currentPriceDate) : null;

  useEffect(() => {
    const priceListPos = priceListRef.current.getBoundingClientRect().left;
    const currentPricePosLeft = currentPriceRef.current.getBoundingClientRect().left;
    const currentPriceWidth = currentPriceRef.current.offsetWidth / 2;


    const priceMinSwitchRef = priceMinRef.current === null ? currentPriceRef.current : priceMinRef.current;
    const priceMaxSwitchRef = priceMaxRef.current === null ? currentPriceRef.current : priceMaxRef.current;

    setPriceMinPosX(`-${priceMinSwitchRef.getBoundingClientRect().width / 2}px`);
    setPriceMaxPosX(`-${priceMaxSwitchRef.getBoundingClientRect().width / 2}px`);

    setProgressBarWidth(currentPricePosLeft - priceListPos + currentPriceWidth);

    if (!isLastPrice) {
      const pricePinWidth = pricePinRef.current.getBoundingClientRect().width;
      setPricePinPosX(`${(currentPricePosLeft - priceListPos + currentPriceWidth) - pricePinWidth / 2}px`);
    }
    setPercent(100 - Math.round(currentPrice / priceArr[priceArr.length - 1].price * 100));
  }, [priceMinRef, priceMaxRef, priceListRef, currentPriceRef, pricePinRef, percent, isLastPrice, currentPrice, priceArr]);

  return (
    <div className={styles.priceSteps}>
      {(!isLastPrice) &&
        <div className={styles.percentWrap} ref={pricePinRef} style={{transform: `translateX(${pricePinPosX})`}}>
          <p className={styles.percent}>-{percent}%</p>
          {nextPriceDate && (
            <p className={`${styles.nextPriceIncrease} ${progressBarWidth > 180 ? styles.nextPriceIncreaseLeft : ''}`}>
              Следующее повышение {nextPriceDate}
            </p>
          )}
        </div>
      }

        <div className={styles.bottom}>
          <div className={styles.scale}>
            <div className={styles.progressBar} style={{width: `${progressBarWidth}px`}} />
          </div>
          <ul className={styles.priceList} ref={priceListRef}>

            {priceArr.map((item, index) => {
              if (index === 0 && item.price !== currentPrice) {
                return (
                  <li
                    className={styles.priceItem}
                    key={item.price}
                    ref={priceMinRef} style={{marginLeft: priceMinPosX}}
                  >
                    {item.price}р
                  </li>
                );
              } else if (index === 0 && item.price === currentPrice) {
                return (
                  <li
                    className={`${styles.priceItem} ${styles.priceItemCurrent}`} style={{marginLeft: priceMinPosX}}
                    ref={currentPriceRef}
                    key={item.price}
                  >
                    {item.price}р
                  </li>
                );
              } else if (index === priceArr.length - 1 && item.price === currentPrice) {
                return (
                  <li
                    className={`${styles.priceItem} ${styles.priceItemCurrent}`} style={{marginRight: priceMaxPosX}}
                    ref={currentPriceRef}
                    key={item.price}
                  >
                    {item.price}р
                  </li>
                );
              } else if (item.price === currentPrice) {
                return (
                  <li
                    className={`${styles.priceItem} ${styles.priceItemCurrent}`}
                    ref={currentPriceRef}
                    key={item.price}
                  >
                    {item.price}р
                  </li>
                );
              } else if (index === priceArr.length - 1 && item.price !== currentPrice) {
                return (
                  <li
                    className={`${styles.priceItem} ${styles.priceItemMax}`} ref={priceMaxRef} style={{marginRight: priceMaxPosX}}
                    key={item.price}
                  >
                    {item.price}р
                  </li>
                );
              } else {
                return (
                  <li
                    className={styles.priceItem}
                    key={item.price}
                  >
                    {item.price}р
                  </li>
                );
              }
            })}

            {/*<li className={styles.priceItem} ref={priceMinRef} style={{marginLeft: priceMinPosX}}>200</li>*/}
            {/*<li className={styles.priceItem}>400</li>*/}
            {/*<li className={styles.priceItem}>600</li>*/}
            {/*<li*/}
            {/*  className={`${styles.priceItem} ${styles.priceItemCurrent}`}*/}
            {/*  ref={currentPriceRef}*/}
            {/*  // onClick={calculateProgressBar}*/}
            {/*>800</li>*/}
            {/*<li className={styles.priceItem}>1000</li>*/}
            {/*<li className={`${styles.priceItem} ${styles.priceItemMax}`} ref={priceMaxRef} style={{marginRight: priceMaxPosX}}>1200</li>*/}
          </ul>
        </div>
    </div>
  );
};

export default PriceSteps;
