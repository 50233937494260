import { useSelector } from 'react-redux';

import { HERO_API } from '@/api';
import { getFormatsStore } from '@/store/slices/app';

import styles from './FormatBig.module.scss';


const FormatBig = () => {
  const formatsCity = useSelector(getFormatsStore);
  const formats = formatsCity?.slice(4) || [];

  if (Object.keys(formats).length === 0) return null;

  return (
    <div className={styles.list}>
      {formats.map((format) => (
        <div className={styles.wrap} key={format.public_id}>
          <h2 className={styles.title}>{format.title}</h2>
          <p className={styles.text}>{format.description}</p>
          <a className={styles.link} href={`${HERO_API}/basket-fill-outside/event?public_id=${format.event_public_id}&city_id=${format.city.id}&format_id=${format.public_id}&insurance=false`} target="_blank" rel="noopener noreferrer">
            Принять участие
          </a>
        </div>
      ))}
    </div>
  );
};

export default FormatBig;
