import { useSelector } from 'react-redux';

import { getSelectedCity } from '@/store/slices/app';
import logoGravsport from '@/assets/images/logo-gravsport@2x.png';
import Container from '@/components/App/Container/Container';

import styles from './Engraving.module.scss';
import { useState } from 'react';
import Popup from '@/components/Popups/Popup/Popup';


const accessCities = ['zabeg2024_524901', 'zabeg2024_498817', 'zabeg2024_1486209', 'zabeg2024_468902', 'zabeg2024_499099'];

const links = {
  1486209: {
    name: 'Екатеринбург',
    link: 'https://gravsport.com/camps/zabegekb2024',
  },
  501175: {
    name: 'Ростов-на-Дону',
    link: 'https://gravsport.com/camps/zabegrnd',
  },
  542420: {
    name: 'Краснодар',
    link: 'https://gravsport.com/camps/zabegkrasnodar',
  },
  498817: {
    name: 'Санкт-Петербург',
    link: 'https://gravsport.com/camps/zabegspb2024',
  },
  468902: {
    name: 'Ярославль',
    link: 'https://gravsport.com/camps/zabegyar',
  },
  524901: {
    name: 'Москва',
    link: 'https://gravsport.com/camps/zrfmoskva2024',
  },
};

const Engraving = () => {
  const selectedCity = useSelector(getSelectedCity);
  const [isOpenPartnerModal, setIsOpenPartnerModal] = useState(false);

  if (!links.hasOwnProperty(selectedCity?.city?.id)) return null;

  return (
    <Container>
      <section className={styles.engraving}>
        <div className={styles.logoWrap}>
          <img className={styles.logo} src={logoGravsport} alt="Gravsport"/>
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>Оформи гравировку на медали финишера!</h2>
          <p className={styles.desc}>Скидка действует при заказе до 10:00 16 мая.</p>
          <a className={styles.btn} href={links[selectedCity?.city.id].link} target="_blank" rel="noopener noreferrer">
            Предзаказ со скидкой 22%
          </a>
        </div>

        <button className={styles.partnerBtn} onClick={() => setIsOpenPartnerModal(true)}>
          <span className="visually-hidden">Дополнительная информация</span>
        </button>
      </section>

      {isOpenPartnerModal && (
        <Popup outsideStyles={styles} handleStatePopup={setIsOpenPartnerModal}>
          <p>ООО «Арена Плюс»</p>
          <p>ИНН: 7606117641</p>
          <p>ERID: 2Vtzqx6pdaR</p>
        </Popup>
      )}
    </Container>
  );
};

export default Engraving;
