const faqCategories = [
  {
    id: 1,
    label: 'О Забеге',
    category: 'about',
  },
  {
    id: 2,
    label: 'До мероприятия',
    category: 'before',
  },
  {
    id: 3,
    label: 'Во время ЗаБега',
    category: 'start',
  },
  {
    id: 4,
    label: 'Детский ЗаБег',
    category: 'child',
  },
  {
    id: 5,
    label: 'После ЗаБега',
    category: 'after',
  },
];

const faqArr = [
  {
    id: 1,
    section: 'about',
    question: 'Какая миссия у ЗаБега?',
    answer:
      'Целью проведения полумарафона «ЗаБег.РФ» является популяризация спорта в России, пропаганда здорового образа жизни, вовлечение граждан в систематические занятия физкультурой, а также определение «Беговой столицы» страны.',
  },
  {
    id: 2,
    section: 'about',
    question: 'Какие цели у ЗаБега?',
    answer:
      'Целью проведения полумарафона «ЗаБег.РФ» является популяризация спорта в России, пропаганда здорового образа жизни, вовлечение граждан в систематические занятия физкультурой, а также определение «Беговой столицы» и «Бегового Университета» страны.',
  },
  {
    id: 3,
    section: 'about',
    question: 'Какие ценности и принципы у ЗаБега?',
    answer: (
      <div>
        <p>
          Объединение. Мы собираем на наших мероприятиях единомышленников, которые любят спорт и активный досуг.{' '}
        </p>
        <p>
          Доступность. Мы стремимся к тому, чтобы массовый любительский спорт был доступен для широких масс и не
          требовал больших финансовых затрат.{' '}
        </p>
        <p>Здоровье. Мы качественно улучшаем жизнь, осознанно выбирая ЗОЖ, а также позитивно влияем на общество.</p>
      </div>
    ),
  },
  {
    id: 4,
    section: 'about',
    question: 'Как выбирается Беговая столица?',
    answer:
      'За звание Беговой столицы соревнуются все города-участники. Победитель определяется по сопоставлению трех показателей: средний километраж всех участников, процентное соотношение участников к жителям города и средняя скорость всех участников. В предыдущие года Беговой столицей становились Казань (2017, 2018), Владивосток (2019), Санкт-Петербург (2021) и Нижний Новгород (2020. 2022).',
  },
  {
    id: 5,
    section: 'before',
    question: 'Как зарегистрироваться?',
    answer: (
      <div>
        Все очень просто, следуй инструкции ниже:
        <ol>
          <li>Выбери город участия и дистанцию.</li>
          <li>Ознакомься с условиями участия и согласись с ними.</li>
          <li>Оплати билет.</li>
          <li>Внеси личные данные участников.</li>
        </ol>
        Ближе к дате забега на указанную при регистрации электронную почту придет рассылка с подробной памяткой, в
        которой будет указана вся необходимая информация о твоем забеге. Или следи за новостями на сайте забег.рф и
        на страницах <a href="https://vk.com/zabegrf">Вконтакте</a>
      </div>
    ),
  },
  {
    id: 6,
    section: 'before',
    question: 'Как вернуть билет?',
    answer: (
      <div>
        Возврат билета на забег оформляется в твоем личном кабинете. Для оформления возврата:
        <ol>
          <li>Войди в личный кабинет.</li>
          <li>Найди билет, который желаешь вернуть.</li>
          <li>Нажми на кнопку на нужном билете - откроется форма билета.</li>
          <li>Если билет зарегистрирован - нажми "Отменить регистрацию" и нажми "да" в появившемся окне.</li>
          <li>Перейди еще раз в нужный билет, нажми там "Оформить возврат" и снова нажми "да" в появившемся окне.</li>
          <li>Перейди в почтовый ящик, указанный при покупке билета, там найдешь чек о возврате.</li>
          <li>Ожидай возврат на ту же карту с которой была произведена оплата.</li>
        </ol>
        <p>
          Ожидай возврата денежных средств. Сроки поступления зависят от твоего банка и, как правило, не составляют
          более 2-х недель. При сдаче билета удерживается комиссия 2,45%.
        </p>
        <p>
          Обращаем внимание, что оформить возврат можно не позднее, чем за 5 дней до даты мероприятия. По истечению
          этого срока денежные средства блокируются и возврат осуществить нельзя. Если денежные средства не
          поступили в указанный срок, обратись, пожалуйста, в свой банк.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    section: 'before',
    question: 'Как поменять город участия или дистанцию?',
    answer: (
      <div>
        Смена города или дистанции не предусмотрена, но ты можешь купить новый билет, оформив возврат денежных
        средств за приобретенный ранее. Деньги тебе вернутся на карту в течение 10 рабочих дней. Обрати внимание,
        что за возврат удерживается комиссия 2,45% и что подать заявку на возврат можно не позднее чем за 5 суток до
        мероприятия.
      </div>
    ),
  },
  {
    id: 8,
    section: 'before',
    question: 'От чего зависит стоимость участия?',
    answer:
      'Полная сумма целевого взноса указана в разделе твоего города на сайте ЗаБега. На ранних этапах регистрации тебе предоставляется скидка. И именно объем скидки уменьшается с приближением даты старта. Величина целевого взноса формируется с учетом стоимости проведения мероприятия в отдельном городе, поэтому в каждом регионе разные цены.',
  },
  {
    id: 9,
    section: 'before',
    question: 'Нужна ли медицинская справка для участия?',
    answer: (
      <div>
        <p>
          Каждый участник должен предоставить медицинскую справку-допуск. Справка должна содержать: ФИО,
          дата рождения, вид спорта (бег), дистанция (1 км, 5 км, 10 км или 21.1 км), результаты осмотра
          врача-терапевта, результат ЭКГ (расшифровка), подпись и печать врача, а также должна быть действительна
          на день спортивного события. Чтобы получить пакет участника, необходимо предъявить удостоверение личности
          и медицинскую справку. Справки не возвращаются. Ксерокопия принимается при предъявлении оригинала.
        </p>

        <p>
          Без наличия медицинской справки участник не допускается к ЗаБегу. К сожалению, исключений быть не может.
        </p>
      </div>
    ),
  },
  {
    id: 10,
    section: 'before',
    question: 'Какие возрастные ограничения предусмотрены?',
    answer: (
      <div>
        <p className="mb-16">
          К участию в ЗаБеге на дистанцию 1 км допускаются все желающие в возрасте от 6 лет и старше.
        </p>

        <ul className="ul mb-16">
          <li>
            Дети 6-17 лет могут принимать участие самостоятельно только при наличии письменного разрешения от
            родителей или опекунов.
          </li>
          <li>
            Если ты хочешь сопровождать своего ребенка на старте, то и ребенку, и сопровождающему потребуются и
            медицинская справка, и билет (при наличии у сопровождающего билета на другую дистанцию в тот же день
            второй билет не требуется).
          </li>
        </ul>

        <p className="mb-16">
          К участию в ЗаБеге на дистанцию ПСБ 5 км допускаются все желающие от 16 лет и старше. Подростки 16-18 лет
          при наличии письменного разрешения от родителей или опекунов.
        </p>

        <p className="mb-16">
          К участию в ЗаБеге на дистанцию 10 и 21,1 км допускаются все желающие от 18 лет и старше.
        </p>

        <p className="mb-16">
          Возрастные ограничения строго соблюдаются, независимо от уровня подготовки участника или наличия каких-либо
          дополнительных документов/разрешений. К сожалению, исключений быть не может. Это связано с тем, что
          мероприятие является любительским и массовым, и в первую очередь направлено на получение удовольствия
          от процесса, а не на проверку себя и своей выносливости. После консультации с Министерством спорта
          и Министерством здравоохранения мы сформировали оптимальные возрастные ограничения в соответствии
          с целями нашего мероприятия.
        </p>
      </div>
    ),
  },
  {
    id: 11,
    section: 'before',
    question: 'Какие социальные льготы предусмотрены?',
    answer:
      'Существует социальная квота в каждом городе ЗаБега. Для получения льготного билета необходимо до 7 мая направить на электронный адрес support@heroleague.ru письмо с указанием ФИО участника, города и дистанции, а также приложить фотографии или сканы документов, подтверждающих право на получение льгот. В ответном письме ты получишь промокод, который необходимо применить при оформлении билета на сайте ЗаБега.',
  },
  {
    id: 12,
    section: 'before',
    question: 'Какое количество билетов я могу оформить в одни руки?',
    answer:
      'Ты можешь оформить любое количество билетов с помощью одного личного кабинета. После оформления билета в своем личном кабинете ты можешь отправить ссылку другу, а он уже введет свои личные данные. Обрати внимание, что покупка оформляется только на нашем сайте забег.рф.',
  },
  {
    id: 13,
    section: 'before',
    question: 'Как узнать расписание стартов?',
    answer:
      'Старт полумарафона на дистанцию 21,1 км пройдет в 09:00 утра по Москве. Участники других дистанций стартуют в зависимости от расписания в их городах. Подробнее о расписании в каждом городе ты узнаешь в памятке участника, которую мы пришлем каждому перед мероприятием. Точное расписание мероприятия в твоем городе будет опубликовано на сайте ЗаБега ближе к дате проведения мероприятия. ',
  },
  {
    id: 14,
    section: 'before',
    question: 'Как узнать маршрут ЗаБега?',
    answer: (
      <div>
        <p className="mb-16">
          Маршрут будет опубликован на сайте забег.рф в разделе твоего города. Также ты можешь следить за нашими
          социальными сетями <a href="https://vk.com/zabegrf" target="_blank" rel="noreferrer noopener">ВКонтакте</a>,
          где мы публикуем всю актуальную информацию о ЗаБеге в разных городах.
        </p>
        <p className="mb-16">
          Процессы согласования маршрутов и места выдачи стартовых пакетов для каждого города ориентировочно должны
          быть завершены к 1 марта 2024 года, однако по некоторым маршрутам и локациям может потребоваться
          дополнительное время на утверждение. Это связано с тем, что необходимо получить согласие от ГИБДД, МВД,
          службы здравоохранения и других ведомств. Опубликовать информацию мы можем только после вынесения
          постановления администрацией города.
        </p>
        <p className="mb-16">
          Полная информация о маршруте, регистрации и месте выдачи стартовых пакетов будет предоставлена в разделе
          твоего города на сайте ЗаБега, а также отправлена на электронную почту, указанную при регистрации.
        </p>
      </div>
    ),
  },
  {
    id: 15,
    section: 'before',
    question: 'Где и как получить стартовый пакет участника?',
    answer: (
      <div>
        <p className="mb-16">
          Информация о месте и датах выдачи стартовых пакетов появится ближе к дате проведения мероприятия. Тебе на
          почту также придет вся необходимая информация.
        </p>
        При себе необходимо иметь:
        <ul className="ul mb-16">
          <li>Удостоверение личности</li>
          <li>Оригинал медицинской справки</li>
          <li>Для льготных участников дополнительно: подтверждающие документы.</li>
          <li>Ветеранам боевых действий дополнительно: удостоверение ветерана боевых действий.</li>
        </ul>
        <p>
          Ты должен оставить справку в обмен на стартовый номер. Забрать ее после ЗаБега нельзя. Ты можешь принести
          копию медицинской справки только в том случае, если с собой будет оригинал.
        </p>
      </div>
    ),
  },
  {
    id: 16,
    section: 'start',
    question: 'Предусмотрены ли камеры хранения и раздевалки?',
    answer:
      'В каждом городе предусмотрены раздевалки и камера хранения для участников ЗаБега. Мы рекомендуем приезжать заранее в спортивной форме, чтобы не тратить время на переодевание. Не сдавайте в пакете ценные или хрупкие вещи. Мы советуем взять ценные вещи с собой или оставить у тех, кто вас сопровождает.',
  },
  {
    id: 17,
    section: 'start',
    question: 'Существуют ли пит-стопы на трассе?',
    answer:
      'На протяжении всей трассы будут расположены пит-стопы с водой и фруктами, чтобы освежиться и подзарядиться. Если температура воздуха будет высокой, то дополнительно на трассе будут поливальные машины.',
  },
  {
    id: 18,
    section: 'start',
    question: 'Что делать, если со мной что-то случится на ЗаБеге?',
    answer:
      'На каждой трассе ЗаБега будут работать машины скорой помощи. На старте и финише будет также расположен медицинский пункт. Пожалуйста, не стесняйся обращаться за помощью, если почувствуешь себя плохо или заметишь, что стало плохо другому участнику. В экстренном случае обращайся к волонтёрам или судьям, привлеки внимание окружающих и вызови медицинскую помощь.',
  },
  {
    id: 19,
    section: 'start',
    question: 'Как происходит награждение участников?',
    answer: (
      <div>
        На дистанции 21,1 км, 10 км награждение производится с 1 по 3 место у мужчин и женщин в каждой возрастной
        категории:
        <ul className="ul mb-16">
          <li>От 18 до 34 лет (включительно);</li>
          <li>От 35 до 44 лет (включительно);</li>
          <li>От 45 до 54 лет (включительно);</li>
          <li>От 55 лет и старше.</li>
        </ul>

        На дистанции ПСБ 5 км награждение производится с 1 по 3 место у мужчин и женщин в каждой возрастной
        категории:
        <ul className="ul mb-16">
          <li>До 17 лет;</li>
          <li>От 18 до 34 лет (включительно);</li>
          <li>От 35 до 44 лет (включительно);</li>
          <li>От 45 до 54 лет (включительно);</li>
          <li>От 55 лет и старше.</li>
        </ul>
        На дистанции 1 км награждение производится с 1 по 3 место у мальчиков и девочек в каждой возрастной
        категории:
        <ul className="ul mb-16">
          <li>От 6 до 9 лет (включительно);</li>
          <li>От 10 до 12 лет (включительно);</li>
          <li>От 13 до 14 лет (включительно):</li>
          <li>От 15 до 17 лет.</li>
        </ul>
        Все финишировавшие участники Соревнования на дистанциях 21,1 км, 10 км, ПСБ 5 км, а также 1 км получают
        памятную медаль.
      </div>
    ),
  },
  {
    id: 21,
    section: 'start',
    question: 'Как происходит дисквалификация участника?',
    answer: (
      <div>
        <p className="mb-16">Судейская коллегия оставляет за собой право дисквалифицировать участника:</p>

        До старта
        <ul className="ul mb-16">
          <li>
            в случае, если врач Соревнования принял решение о снятии участника с дистанции по состоянию здоровья;
          </li>
          <li>в случае участия без стартового номера;</li>
          <li>в случае внесения любых изменений в стартовый номер участника;</li>
          <li>
            в случае, если участник стартовал из кластера, отличного от указанного на его стартовом номере, за
            исключением случаев, когда он вы-брал старт из кластера с большим временем прохождения дистанции;
          </li>
          <li>
            в случае, если участник начал забег до официального старта Забега, после закрытия старта или участник не
            пересёк линию старта;
          </li>
          <li>в случае, если участник стартовал после закрытия стартового створа.</li>
        </ul>

        Во время преодоления дистанции
        <ul className="ul mb-16">
          <li>
            в случае выявления фактов сокращения участником дистанции, использования любых механических средств
            передвижения;
          </li>
          <li>
            в случае, если участник не укладывается в контрольное время закрытия участков дистанции Соревнования;
          </li>
          <li>
            в случае, если у участника отсутствует считывание чипа на любой промежуточной точке по дистанции за
            исключением старта и финиша;
          </li>
          <li>в случае участия под стартовым номером, зарегистрированным на другого участника;</li>
          <li>
            в случае финиша на дистанции, отличной от заявленной при регистрации и указанной на стартовом номере;
          </li>
          <li>
            в случае, если участник финишировал после закрытия финиша. Решение о закрытии финиша принимается главным
            судьей в соответствии с лимитом прохождения трассы.
          </li>
        </ul>

        <p className="mb-16">
          Спортсмен может быть дисквалифицирован за неспортивное поведение на Соревновании. Решение о
          дисквалификации принимается главным судьей в соответствии с пунктом 125.5 IAAF.
        </p>
        Если участник не укладывается в контрольное время закрытия трассы, участник обязан продолжить движение по
        тротуару в соответствии с правилами ПДД, либо в автобусе.
      </div>
    ),
  },
  {
    id: 22,
    section: 'start',
    question: 'Могу ли я привести зрителей на ЗаБег?',
    answer:
      'Можно приходить всей семьей и с друзьями любого возраста, чтобы поддержать спортсменов на трассе и финише. К тому же для всех гостей мероприятия будут подготовлены активности и развлечения. Точное расписание мероприятия ты сможешь узнать в разделе своего города.',
  },
  {
    id: 23,
    section: 'start',
    question: 'Где найти туалет перед и после старта?',
    answer:
      'Туалеты будут расположены в стартовом городке и на трассе. Не откладывайте посещение туалета на последний момент. Самые большие очереди в туалет образуются незадолго до старта.',
  },
  {
    id: 24,
    section: 'child',
    question: 'Какие условия для участия детей?',
    answer: (
      <div>
        <p className="mb-16">
          К участию в ЗаБеге на дистанцию 1 км допускаются все желающие в возрасте от 6 лет и старше.
        </p>

        <ul className="ul mb-16">
          <li>
            Дети 6-17 лет могут принимать участие самостоятельно только при наличии письменного разрешения от
            родителей или опекунов.
          </li>
          <li>
            Если ты хочешь сопровождать своего ребенка на старте, то и ребенку, и сопровождающему потребуются
            и медицинская справка, и билет (при наличии у сопровождающего билета на другую дистанцию в тот же день
            второй билет не требуется).
          </li>
        </ul>

        <p className="mb-16">
          К участию в ЗаБеге на дистанцию ПСБ 5 км допускаются все желающие от 16 лет и старше.
        </p>

        <p className="mb-16">
          Подростки 16-18 лет при наличии письменного разрешения от родителей или опекунов.
        </p>
      </div>
    ),
  },
  {
    id: 25,
    section: 'child',
    question: 'Необходимо ли разрешение от родителей для участия детей в ЗаБеге?',
    answer: (
      <div>
        Всем участникам младше 18 лет необходимо письменное разрешение родителей. Пример разрешения родителей ты
        можешь посмотреть <a href="https://забег.рф/soglasie.pdf">здесь</a>. Без наличия письменного разрешения
        родителей участник не допускается к ЗаБегу. К сожалению, исключений быть не может.
      </div>
    ),
  },
  {
    id: 27,
    section: 'after',
    question: 'Где посмотреть результаты участия?',
    answer: (
      <p>
        Результаты ЗаБега будут опубликованы на сайте забег.рф и в группе ЗаБега во {' '}
        <a href="https://vk.com/zabegrf" target="_blank" rel="noreferrer noopener">ВКонтакте</a> в течение двух
        недель после мероприятия.
      </p>
    ),
  },
  {
    id: 28,
    section: 'after',
    question: 'Где найти фото с моим участием?',
    answer: (
      <div>
        <p className="mb-16">
          Фотографии с мероприятия можно будет посмотреть в группе ЗаБега в {' '}
          <a href="https://vk.com/zabegrf" target="_blank" rel="noreferrer noopener">ВКонтакте</a>. Они будут
          опубликованы в течение нескольких недель после мероприятия.
        </p>

        <p>
          А также ты можешь найти свои фото по фамилии, номеру участника или фото лица на сайте {' '}
          <a href="https://photo.heroleague.ru">photo.heroleague.ru</a> после мероприятия.
        </p>
      </div>
    ),
  },
  {
    id: 29,
    section: 'after',
    question: 'Куда обратиться с пожеланиями или претензиями в адрес организаторов?',
    answer: (
      <div>
        <p className="mb-16">
          Участник вправе подать протест или претензию, которые рассматриваются судейской коллегией, в состав
          которой входят главный судья, стар-ший судья стартово-финишной зоны и главный секретарь.
        </p>

        К протестам и претензиям могут относиться:
        <ul className="ul mb-16">
          <li>протесты и претензии, влияющие на распределение призовых мест;</li>
          <li>
            протесты и претензии, касающиеся неточности в измерении вре-мени, за которое участник пробежал
            дистанцию;
          </li>
          <li>протесты и претензии, касающиеся дисквалификации участника за неспортивное поведение.</li>
        </ul>

        Остальные претензии могут быть проигнорированы комиссией в силу их незначительности, а именно: некорректные
        анкетные данные (название города, название бегового клуба). При подаче претензии необходимо указать
        следующие данные:
        <ul className="ul mb-16">
          <li>фамилия и имя (анонимные претензии не рассматриваются);</li>
          <li>суть претензии (в чем состоит претензия);</li>
          <li>материалы, доказывающие ошибку (фото, видео материалы, показатели трекеров).</li>
        </ul>

        <p className="mb-16">
          Претензии принимаются только от участников Соревнования или от третьих лиц, являющихся официальными
          представителями участников.
        </p>

        <p className="mb-16">Сроки подачи протестов и претензий, а также способ их подачи.</p>

        <p className="mb-16">
          Протесты и претензии, влияющие на распределение призовых мест в абсолютном первенстве, принимаются
          судейской коллегией в письменной или устной форме с момента объявления победителей и до официальной
          церемонии награждения. Распределение призовых мест после церемонии награждения может быть пересмотрено
          судейской коллегией только при вы-явлении фактов нарушения победителем или призером действующих правил,
          если выявление нарушений было невозможно до церемонии награждения. Решение о пересмотре призовых мест
          принимается Директором Соревнования.
        </p>

        <p className="mb-16">
          По остальным вопросам участник вправе подать протест или претензию с 12:00 часов 19 мая 2024 года до 18:00
          часов 17 июня 2023 года. Про-тест или претензия должны быть направлены в письменной форме на адрес
          электронной почты{' '}
          <a href="mailto:support@heroleague.ru" target="_blank" rel="noopener noreferrer">
            support@heroleague.ru
          </a>
          .
        </p>
      </div>
    ),
  },
];

export { faqCategories, faqArr };
