import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { isOnlineCity } from '@/utils/common';
import { getCitiesStore } from '@/store/slices/app';

import Popup from '../Popup/Popup';
import { alphabetArr } from './choiceCityMock';
import styles from './ChoiceCityPopup.module.scss';


const ChoiceCityPopup = ({ handleStateCity, onChangeCity }) => {
  const cities = useSelector(getCitiesStore);
  const [activeLetter, setActiveLetter] = useState('А');
  const [filteredCities, setFilteredCities] = useState([]);
  const searchRef = useRef();

  useEffect(() => {
    if (cities?.length > 0) {
      isOnlineCity(cities[0]);
    }
  }, [cities]);

  useEffect(() => {

    function handleResize() {
      if (window.innerWidth < 768) {
        filtersCities('Все');
        setActiveLetter('Все');
      }
      if (window.innerWidth > 767) {
        filtersCities('А');
        setActiveLetter('А');
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onClickLetterFilter = (letter) => {
    setActiveLetter(letter);
    filtersCities(letter);
  };

  const onChangeSearch = () => {
    if (searchRef.current.value.length === 0) {
      setActiveLetter('А');
      filtersCities('А');
    } else {
      setActiveLetter(searchRef.current.value);
      filtersCities(searchRef.current.value);
    }
  };

  const onClickCity = (city) => {
    onChangeCity(city.public_id);
    handleStateCity(false);
  };

  const filtersCities = (letter = activeLetter) => {
    let newCities = [];

    if (letter?.length === 1 || letter === 'Все') {
      newCities = cities.filter((item) => letter.toLowerCase() === item.address.slice(0, letter.length).toLowerCase() || letter === 'Все');
    } else if (letter?.length > 1) {
      newCities = cities.filter((item) => item.address.toLowerCase().includes(letter.toLowerCase()));
    }

    setFilteredCities(newCities);
  };

  return (
    <Popup handleStatePopup={handleStateCity} outsideStyles={styles}>

      <div >
        <h2 className={styles.title}>Выбор города</h2>
        <form className={styles.search}>
          <input className={styles.searchField} ref={searchRef} onChange={onChangeSearch} type="search" placeholder="Введите название вашего города"/>
          <button className={styles.searchBtn} type="button">Найти</button>
        </form>
        <div className={styles.citiesList}>
          <div className={styles.leftColumn}>
            <h3  className={styles.bigCitiesTitle}>Крупные города</h3>
            <ul className={styles.bigCities}>
              {cities.map((item) => (
                (item.city.id === 999999 || item.city.id === 524901 || item.city.id === 498817) && (
                  <li onClick={() => onClickCity(item)} className={styles.bigCitiesItem} key={item.public_id}>
                    {item.address}
                  </li>
                )
              ))}
              {cities.map((item, index) => (
                (index < 10 && item.city.id !== 524901 && item.city.id !== 498817) && (
                  <li onClick={() => onClickCity(item)} className={styles.bigCitiesItem} key={item.public_id}>
                    {item.address}
                  </li>
                )
              ))}
            </ul>
          </div>
          <div className={styles.filter}>
            <ul className={styles.alphabet}>
              {alphabetArr.map(item => (
                <li
                  className={`${styles.letter} ${activeLetter.slice(0, 1).toLowerCase() === item.symbol.toLowerCase() && styles.letterActive}`}
                  onClick={() => onClickLetterFilter(item.symbol)}
                  key={item.id}
                >
                  {item.symbol}
                </li>
              ))}
            </ul>
            <ul className={styles.results}>
              {filteredCities.map(item => (
                <li key={item.public_id}>
                  {item?.external_url && <a className={`${styles.bigCitiesItemLink} ${styles.resultsItem}`} href={item.external_url} target="_blank" rel="noopener noreferrer">{item.address}</a>}
                  {!item?.external_url && <span className={styles.resultsItem} onClick={() => onClickCity(item)}>
                  {item.address}
                    {isOnlineCity(item) && <span className={styles.cityOnline}> (Онлайн забег)</span>}
                  </span>}
                </li>
              ))}
            </ul>
            {/*<button*/}
            {/*  className={styles.link}*/}
            {/*  type="button"*/}
            {/*  onClick={() => onClickCity('Другой город')}*/}
            {/*>*/}
            {/*  Нет твоего города? Беги онлайн*/}
            {/*</button>*/}
          </div>
        </div>
      </div>

    </Popup>
  );
};

export default ChoiceCityPopup;
