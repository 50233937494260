import videoPreview from '@/assets/images/play2.jpg';

import styles from './DescriptionOnline.module.scss';


export default function VideoPlayer({ video, width = '645px', height = '379px' }) {
  return (
    <div className={styles.wrapper}>
      <video className={styles.videoFrame} poster={videoPreview} width={width} height={height} controls="controls">
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};
