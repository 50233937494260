import Container from '@/components/App/Container/Container';

import { partners } from './partnersData';
import styles from './Partners.module.scss';
import { useState } from 'react';
import Popup from '@/components/Popups/Popup/Popup';


const Partners = ({isOnline}) => {
  const [isOpenPartnerModal, setIsOpenPartnerModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState({});

  const handleOpenPartnerModal = (partner) => {
    setSelectedPartner(partner);
    setIsOpenPartnerModal(true);
  };

  const handleClosePartnerModal = (state) => {
    setSelectedPartner({});
    setIsOpenPartnerModal(state);
  };

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.yourPartners}>
          <h2 className={styles.title}>Твои партнеры</h2>
          <p className={styles.text}>
            Если вы хотите присоединиться к крупнейшему синхронному полумарафону России и мира, то заполните простую
            форму — наша команда свяжется с вами!
          </p>
          <a className={styles.link} href="https://heroleague.ru/partners#application" target="_blank" rel="noopener noreferrer">
            Стать партнером
          </a>
        </div>
        {/* <Corporate /> */}
      </div>

      {partners.map((partner) => (
        <div className={styles.partnerList} key={partner.id}>
          <h4 className={styles.label}>{partner.title}</h4>
          <ul className={styles.partnerLogos}>
            {partner.items.map((item) => (
              <li className={styles.partnerLogosItem} key={item.id}>
                {item.link ? (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <img src={item.logo} width={item.width} alt="Партнер"/>
                  </a>
                ) : (
                  <img src={item.logo} width={item.width} alt="Партнер"/>
                )}

                {Object.keys(item.adInfo)?.length > 0 && (
                  <button className={styles.partnerBtn} onClick={() => handleOpenPartnerModal(item)}>
                    <span className="visually-hidden">Дополнительная информация</span>
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}

      {isOpenPartnerModal && (
        <Popup outsideStyles={styles} handleStatePopup={handleClosePartnerModal}>
          <p>{selectedPartner?.adInfo.company}</p>
          <p>ИНН: {selectedPartner?.adInfo.inn}</p>
          <p>ERID: {selectedPartner?.adInfo.token}</p>
        </Popup>
      )}
    </Container>
  );
};

export default Partners;
