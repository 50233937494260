import { useEffect, useState } from 'react';

import psbBanner from '@/assets/images/psb-banner.jpeg';
import psbBannerM from '@/assets/images/psb-banner-m.jpg';
import Container from '@/components/App/Container/Container';

import styles from './Banner.module.scss';
import Popup from '../Popups/Popup/Popup';


const Banner = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpenBannerModal, setIsOpenBannerModal] = useState(false);

  const handleOpenBannerModal = (evt) => {
    setIsOpenBannerModal(true);
    evt.preventDefault();
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) setIsMobile(true);
      if (window.innerWidth > 767) setIsMobile(false);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Container>
      <section className={styles.wrapper}>
        <a className={styles.link} href="https://ib.psbank.ru/store/products/your-cashback-new?LG=111042165&utm_source=zabeg_rf&utm_medium=cpm&utm_campaign=banner_rb_2380_460_image&utm_content=5" target="_blank" rel="noreferrer">
          <img className={styles.img} src={isMobile ? psbBannerM : psbBanner} alt="Псб" />
          <button className={styles.bannerBtn} onClick={handleOpenBannerModal}>
            <span className="visually-hidden">Дополнительная информация</span>
          </button>
        </a>
      </section>

      {isOpenBannerModal && (
        <Popup outsideStyles={styles} handleStatePopup={setIsOpenBannerModal}>
          <p>ПАО "Промсвязьбанк"</p>
          <p>ИНН: 7744000912</p>
          <p>{isMobile ? 'ERID: 2VtzqupsHmn' : 'ERID: 2VtzqwsTdWL'}</p>
        </Popup>
      )}
    </Container>
  );
};

export default Banner;
