import { useState } from 'react';

import api from '@/api';

import Popup from '../Popup/Popup';
import styles from './Online2021Popup.module.scss';


const Online2021Popup = ({handleStatePopup}) => {
  const [isFocusDate, setFocusDate] = useState(false);
  const [checkTown, setCheckTown] = useState(true);
  const [chekTicket, setChekTicket] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [formData, setFormData] = useState({});

  const handleBlurDate = (evt) => {
    if (evt.target.value === '' ) setFocusDate(false);
  };

  const handleSubmitForm = async (evt) => {
    evt.preventDefault();

    const sendObj = {
      first_name: formData.first_name,
      second_name: formData.second_name,
      birth_date: formData.birth_date,
      email: formData.email,
    };

    if (chekTicket) sendObj.ticket = true;

    try {
      const response = await api.post('/api/zabeg2021', sendObj);
      if (response.status === 200) {
        setSubmitting(true);

        // setTimeout(() => {
        //   setSubmitting(false);
        //   setValidForm(false);
        // }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeField = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    setFormData({...formData, [name]: value});
  };

  return (
    <Popup handleStatePopup={handleStatePopup} outsideStyles={styles}>

      { !isSubmitting ?
        <>
          <h2 className={styles.title}>Заполните форму</h2>
          <form className={styles.form} onSubmit={handleSubmitForm}>
            <input
              className={styles.field}
              type="text"
              name="first_name"
              placeholder="Ваше имя"
              // ref={nameRef}
              onChange={handleChangeField}
              required
            />
            <input
              className={styles.field}
              type="text"
              name="second_name"
              placeholder="Ваша фамилия"
              // ref={nameRef}
              onChange={handleChangeField}
              required
            />
            <input
              className={styles.field}
              type={isFocusDate ? 'date' : 'text'}
              placeholder="Дата рождения"
              name="birth_date"
              onFocus={() => setFocusDate(true)}
              onBlur={handleBlurDate}
              // ref={nameRef}
              onChange={handleChangeField}
              required
            />
            <input
              className={styles.field}
              type="email"
              name="email"
              placeholder="Ваш email"
              // ref={nameRef}
              onChange={handleChangeField}
              required
            />
            <>
              <input
                onChange={() => setChekTicket(!chekTicket)}
                className={`${styles.checkbox} visually-hidden`}
                type="checkbox" id="check-ticket-ref"
              />
              <label className={styles.label} htmlFor="check-ticket-ref">У меня есть билет на Забег Москва 2021</label>
            </>
            <>
              <input
                defaultChecked={checkTown}
                onChange={() => setCheckTown(!checkTown)}
                className={`${styles.checkbox} visually-hidden`}
                type="checkbox" id="check-town-ref"
                required
              />
              <label className={styles.label} htmlFor="check-town-ref">Я даю согласие на обработку персональных данных</label>
            </>
            <button className={styles.btn} type="submit">Отправить</button>
          </form>
        </>
      :
        <div className={styles.sendingWrap}>
          <h2 className={styles.formTitle}>Данные отправлены</h2>
        </div>
      }
    </Popup>
  );
};

export default Online2021Popup;
