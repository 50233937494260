import { useState } from 'react';
import { useSelector } from 'react-redux';

import mainLogo from '@/assets/images/main-logo.svg';
import Container from '@/components/App/Container/Container';
import { getCitiesStore, getSelectedCity } from '@/store/slices/app';
import MainMenuPopup from '@/components/Popups/MainMenu/MainMenuPopup';
import ChoiceCityPopup from '@/components/Popups/ChoiceCity/ChoiceCityPopup';

import styles from './Header.module.scss';


const Header = ({onChangeCity, children, className}) => {
  const cities = useSelector(getCitiesStore);
  const selectedCity = useSelector(getSelectedCity);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isOpenCityModal, setOpenCityModal] = useState(false);

  const onClickChoiceCity = () => {
    setOpenCityModal(true);
  };

  const onOpenMenu = () => {
    setMenuOpen(true);
  };

  return (
    <header className={`${styles.header} ${className}`}>
      <Container>
        <div className={styles.top}>
          <div className={styles.mobileMenu}>
            <button
              className={styles.btnMenu}
              type="button"
              onClick={onOpenMenu}
            >
              <span className="visually-hidden">Открыть меню</span>
            </button>
            {cities?.length > 0 && (
              <div className={styles.choiceCity}>
                <p>
                  <span className={styles.choiceCityLabel}>Ваш город: </span>
                  <span className={styles.choiceCityName} onClick={onClickChoiceCity}>{selectedCity?.city?.name_ru}</span>
                </p>
              </div>
            )}
            <img className={styles.logoMobile} src={mainLogo} width="48" height="23" alt="Логотип Забег.РФ"/>
          </div>

        </div>

        {children}

      </Container>

      {isMenuOpen && <MainMenuPopup handleStateMenu={setMenuOpen} handleStateCity={setOpenCityModal} />}
      {isOpenCityModal && <ChoiceCityPopup handleStateCity={setOpenCityModal} onChangeCity={onChangeCity} />}
    </header>
  );
};

export default Header;
