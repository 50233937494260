import nrjLogo from '@/assets/images/partners/logo-nrj.png';
import psbLogo from '@/assets/images/partners/partner-2.png';
import psbCordiant from '@/assets/images/partners/logo-cordiant.svg';
import maerLogo from '@/assets/images/partners/logo-maer.svg';
import mtchLogo from '@/assets/images/partners/logo-match.png';
import riaLogo from '@/assets/images/partners/sport_black_rus.svg';
import sport24Logo from '@/assets/images/partners/logo-sport24.png';

// import izLogo from '@/images/partners/logo-iz.svg';
import senegskayaLogo from '@/assets/images/partners/logo-senegskaya.svg';
import bionovaLogo from '@/assets/images/partners/logo-bionova.svg';
import logoMiratorg from '@/assets/images/partners/logo-miratorg.svg';
import logoMifuds from '@/assets/images/partners/logo-mirfuds.svg';
import logoMoskart from '@/assets/images/partners/logo-moskart.svg';
import logoMoskvich from '@/assets/images/partners/logo-moskvich.svg';
import logoRaveTape from '@/assets/images/partners/logo-rave-tape.svg';
import logoFlow from '@/assets/images/partners/logo-flow.svg';
import logoEnergyfm from '@/assets/images/partners/logo-energyfm.svg';
// import rzdLogo from '@/images/partners/logo-rzd.svg';
// import lentaOnlineLogo from '@/images/partners/logo-lenta-online.svg';
// import accesstyleLogo from '@/images/partners/logo-accesstyle.svg';
// import yamaguchiLogo from '@/images/partners/logo-yamaguchi.svg';
// import rvLogo from '@/images/partners/logo-rv-line.svg';
// import uralLogo from '@/images/partners/logo-ural.svg';
// import altayLogo from '@/images/partners/logo-altay-superfood.svg';
// import kizLogo from '@/images/partners/logo-kiz.svg';
// import grLogo from '@/images/partners/logo-gruzovichkof.svg';
// import snzhLogo from '@/images/partners/logo-snzh.svg';
// import lahtaLogo from '@/images/partners/logo-lahta.svg';


export const partners = [
  {
    id: 0,
    title: 'Генеральный спонсор',
    items: [
      {
        id: 0,
        logo: psbLogo,
        width: 230,
        adInfo: {
          company: 'ПАО "Промсвязьбанк"',
          inn: 7744000912,
          token: '2VtzqvH4CeV',
        },
      },
    ],
  },
  {
    id: 1,
    title: 'Официальный партнёр',
    items: [
      {
        id: 0,
        logo: psbCordiant,
        width: 230,
        adInfo: {
          company: 'АО «Кордиант»',
          inn: 7601001509,
          token: '2VtzqvqhEo9',
        },
      },
    ],
  },
  // {
  //   id: 1,
  //   title: 'Генеральные информационные партнёры',
  //   items: [
  //     {
  //       id: 0,
  //       logo: riaLogo,
  //       width: 235,
  //       adInfo: {},
  //     },
  //     {
  //       id: 1,
  //       logo: maerLogo,
  //       width: 235,
  //       adInfo: {},
  //     },
  //     {
  //       id: 2,
  //       logo: mtchLogo,
  //       width: 235,
  //       adInfo: {},
  //     },
  //     {
  //       id: 3,
  //       logo: nrjLogo,
  //       width: 90,
  //       adInfo: {},
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   title: 'Информационные партнёры',
  //   items: [
  //     {
  //       id: 0,
  //       logo: sport24Logo,
  //       width: 235,
  //       adInfo: {},
  //     },
      // {
      //   id: 1,
      //   logo: izLogo,
      //   width: 235,
      // },
      // {
      //   id: 3,
      //   logo: kizLogo,
      //   width: 200,
      // },
  //   ],
  // },
  {
    id: 2,
    title: 'Партнёры',
    items: [
      {
        id: 0,
        logo: logoMoskvich,
        width: 190,
        height: 75,
        adInfo: {
          company: 'АО МАЗ Москвич',
          inn: 7709259743,
          token: '2VtzqwySxN3',
        },
      },
      {
        id: 1,
        logo: senegskayaLogo,
        width: 178,
        link: 'http://www.senegskaya.ru/',
        adInfo: {
          company: 'ООО «ФКПЧФ БОБИМЭКС ТМ»',
          inn: 7710301171,
          token: '2VtzqxFXph4)',
        },
      },
      {
        id: 2,
        logo: bionovaLogo,
        width: 107,
        adInfo: {
          company: 'ООО "Нова ПродуктАГ"',
          inn: 7707288097,
          token: '2VtzququQDM',
        },
      },
      {
        id: 3,
        logo: logoRaveTape,
        width: 180,
        height: 27,
        link: 'https://ravetape.ru/',
        adInfo: {
          company: 'ООО СПОРТРИА',
          inn: 7804504762,
          token: '2VtzqvwWpEA',
        },
      },
      {
        id: 4,
        logo: logoFlow,
        width: 105,
        height: 105,
        link: 'https://flowrecovery.ru/',
        adInfo: {
          company: 'ООО СПОРТРИА',
          inn: 7804504762,
          token: '2VtzqviqDWn',
        },
      },
      {
        id: 5,
        logo: logoMoskart,
        width: 120,
        height: 68,
        adInfo: {
          company: 'ООО Московский Картофель',
          inn: 7718528015,
          token: '2VtzqvWYZVA',
        },
      },
      {
        id: 6,
        logo: logoMifuds,
        width: 126,
        height: 97,
        adInfo: {
          company: 'ООО МИРФУДС',
          inn: 4011033206,
          token: '2VtzquWmjMA',
        },
      },
      {
        id: 7,
        logo: logoMiratorg,
        width: 170,
        height: 42,
        adInfo: {
          company: 'ООО «АМА-ГРУПП»',
          inn: 7708291568,
          token: '2Vtzqwo3SPz',
        },
      },
      {
        id: 8,
        logo: logoEnergyfm,
        width: 95,
        height: 122,
        adInfo: {
          company: 'ООО "ГПМ Радио"',
          inn: 7714353188,
          token: '2Vtzqx8QF2G',
        },
      },
      // {
      //   id: 2,
      //   logo: lentaOnlineLogo,
      //   width: 350,
      // },
      // {
      //   id: 3,
      //   logo: accesstyleLogo,
      //   width: 150,
      // },
      // {
      //   id: 4,
      //   logo: yamaguchiLogo,
      //   width: 180,
      // },
      // {
      //   id: 5,
      //   logo: rvLogo,
      //   width: 180,
      // },
      // {
      //   id: 6,
      //   logo: uralLogo,
      //   width: 100,
      // },
      // {
      //   id: 7,
      //   logo: altayLogo,
      //   width: 180,
      // },
      // {
      //   id: 8,
      //   logo: grLogo,
      //   width: 180,
      // },
      // {
      //   id: 9,
      //   logo: snzhLogo,
      //   width: 110,
      // },
      // {
      //   id: 10,
      //   logo: lahtaLogo,
      //   width: 120,
      // },
    ],
  },
];
