import { useState } from 'react';

import Container from '@/components/App/Container/Container';

import styles from './Faq2.module.scss';
import { faqArr, faqCategories } from './faq-data';


const Accordion = ({category}) => {
  const [openedAccordionItem, setOpenedAccordionItem] = useState(-1);

  const handleClickAccordion = (index) => {
    if (openedAccordionItem === index) {
      setOpenedAccordionItem(-1);
    } else {
      setOpenedAccordionItem(index);
    }
  };

  return (
    <div className={styles.accordion}>
      {faqArr.map((el, index) => el.section === category && (
        <div className={`${styles.accordionItem} ${openedAccordionItem === index ? styles.accordionItemOpen : ''}`} key={el.id}>
          <div className={styles.accordionHead} onClick={() => handleClickAccordion(index)}>
            <b className={styles.accordionLabel}>{el.question}</b>
            <button className={styles.accordionButton}>
              {openedAccordionItem === index ? (
                'Скрыть ответ'
              ) : (
                'Читать ответ'
              )}
            </button>
          </div>
          {openedAccordionItem === index && (
            <div className={styles.accordionPanel}>
              <div>{el.answer}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const Faq2 = () => {
  // const location = useLocation();

  const [openedCategoryItem, setOpenedCategoryItem] = useState('about');

  return (
    <Container>
      <section className={styles.faq} id="faq">
        <h2 className={styles.title}>Вопрос-Ответ</h2>
        <p className={styles.description}>
          Специально для вас, собрали самые популярные вопросы
        </p>

        <div className={styles.categories}>
          {faqCategories.map((item) => (
            <div className={styles.categoryItem} key={item.id}>
              <div className={styles.categoryHead}>
                <button
                  onClick={() => setOpenedCategoryItem(item.category)}
                  className={`${styles.categoryButton} ${item.category === openedCategoryItem ? styles.categoryButtonActive : ''}`}
                >
                  {item.label}
                </button>
              </div>

              {item.category === openedCategoryItem && (
                <div className={styles.categoryPanelMobile}>
                  <Accordion category={item.category} />
                </div>
              )}

            </div>
          ))}
        </div>

        <div className={styles.categoryPanelDesktop}>
          <Accordion category={openedCategoryItem} />
        </div>

        {/*{(location.pathname !== '/faq' && location.pathname !== '/%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81-%D0%BE%D1%82%D0%B2%D0%B5%D1%82') && (*/}
        {/*  <Link className={styles.link} to="/вопрос-ответ">Перейти в раздел FAQ</Link>*/}
        {/*)}*/}
      </section>
    </Container>
  );
};

export default Faq2;
