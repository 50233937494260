import Container from '@/components/App/Container/Container';
import starterImg from '@/assets/images/starter-pack@1x.png';
import starterImg2x from '@/assets/images/starter-pack@2x.png';

import styles from './StarterPack.module.scss';
import { useState } from 'react';
import SizesPopup from '@/components/Popups/SizesPopup/SizesPopup';
import { useMediaQuery } from 'react-responsive';


const StarterPack = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Container>
      <h2 className={styles.title} id="starterPack">Стартовый пакет</h2>
      <div className={styles.columns}>
        <div className={styles.leftColumn}>
          <p className={styles.description}>Почувствуй себя <br/> профессиональным спортсменом!</p>
          <div className={styles.imgContainerMobile}>

            {/*1х: 777px; 2x: 1554px*/}
            <img className={styles.imgStarter} src={starterImg} srcSet={`${starterImg2x} 2x`} alt="Стартовый пакет"/>
          </div>

          {isMobile && (
            <button className={styles.buttonSizes} onClick={() => setIsOpenModal(true)}>Таблица размеров</button>
          )}

          <h3 className={styles.listTitle}>Стартовый пакет каждому участнику:</h3>
          <ol className={styles.list}>
            <li className={styles.listItem}>Футболка участника</li>
            <li className={styles.listItem}>Медаль на финише</li>
            <li className={styles.listItem}>Номер участника с чипом хронометража</li>
            <li className={styles.listItem}>Брендированный мешок</li>
            <li className={styles.listItem}>Электронный сертификат участника</li>
            <li className={styles.listItem}>Подарки от партнеров</li>
          </ol>
          <div  className={styles.footnoteContainer}>
            <p className={styles.footnote}><span className={styles.footnoteStar}>*</span>Пакет участника может меняться в зависимости от выбранного города</p>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.imgContainer}>

            {/*1х: 777px; 2x: 1554px*/}
            <img  className={styles.imgStarter} src={starterImg} srcSet={`${starterImg2x} 2x`} alt="Стартовый пакет"/>
          </div>
          <div className={styles.awardContainer}>
            <p className={styles.award}>Твоя награда - заслуженное признание и медаль финишера!</p>
            <a className={styles.link} href="#formats">Получить</a>
          </div>
        </div>
      </div>

      {!isMobile && (
        <button className={styles.buttonSizes} onClick={() => setIsOpenModal(true)}>Таблица размеров</button>
      )}

      <SizesPopup isShow={isOpenModal} onHandleState={setIsOpenModal} />
    </Container>
  );
};

export default StarterPack;
