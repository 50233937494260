import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { times } from '@/utils/common';
import Faq2 from '@/components/Faq2/Faq2';
import Footer from '@/components/Footer/Footer';
import icon1 from '@/assets/images/feature1.svg';
import icon3 from '@/assets/images/feature3.svg';
import icon4 from '@/assets/images/feature4.svg';
import Formats from '@/components/Formats/Formats';
import Comfort from '@/components/Comfort/Comfort';
import icon2 from '@/assets/images/feature2@1x.png';
import Emotions from '@/components/Emotions/Emotions';
import FormatBig from '@/components/FormatBig/FormatBig';
import CityRoute from '@/components/CityRoute/CityRoute';
import backFormatCity from '@/assets/images/cover22@1x.png';
import FormatCity from '@/components/FormatCity/FormatCity';
import Container from '@/components/App/Container/Container';
import StarterPack from '@/components/StarterPack/StarterPack';
import HeaderMain from '@/components/Header/HeaderMain/HeaderMain';
import { getFormatsStore, getSelectedCity } from '@/store/slices/app';
import RunningCapital from '@/components/RunningCapital/RunningCapital';
import DescriptionOnline from '@/components/DescriptionOnline/DescriptionOnline';

// import Faq from '@/components/Faq/Faq';
import Banner from '@/components/Banner/Banner';
// import Running from '@/components/Running/Running';
import Partners from '@/components/Partners/Partners';
import Corporate from '@/components/Corporate/Corporate';
// import img1 from '@/assets/images/sportsday_banner.png';
// import img2 from '@/assets/images/sportsday_banner2.png';
import Engraving from '@/components/Engraving/Engraving';
// import BelPopup from '@/components/Popups/BelPopup/BelPopup';
// import NextPricePopup from '@/components/Popups/NextPrice/NextPricePopup';
// import SportsDayPopup from '@/components/Popups/SportsDayPopup/SportsDayPopup';
import ImportantInfoPopup from '@/components/Popups/ImportantInfo/ImportantInfoPopup';

const descriptionMainList = [
  {
    id: 0,
    text: 'Шоу-программа для болельщиков и участников',
    icon: icon1,
    width: 161,
    height: 162,
  },
  {
    id: 1,
    text: 'Детская трасса и развлечения для юных атлетов',
    icon: icon2,
    width: 171,
    height: 123,
  },
  {
    id: 2,
    text: 'Лучшие маршруты в сердце твоего города',
    icon: icon3,
    width: 159,
    height: 183,
  },
  {
    id: 3,
    text: 'Возможность побить мировой рекорд',
    icon: icon4,
    width: 151,
    height: 150,
  },
];

const Main = ({ onChangeCity }) => {
  const formats = useSelector(getFormatsStore);
  const selectedCity = useSelector(getSelectedCity);
  const [date, setDate] = useState(0);

  useEffect(() => {
    if (formats && formats.length > 0) setDate(formats[0].start_time);
    else setDate(1685858400);
  }, [date, formats]);

  return (
    <>
      <HeaderMain onChangeCity={onChangeCity} />
      <DescriptionOnline
        title={<>{times.getShortDate(date)} страна <br /> в одном ритме</>}
        subtitle={'VIII Всероссийский полумарафон'}
        desc={<>
          <p>
            {selectedCity?.city?.name_ru !== 'Москва' ?
              'Вся страна и 1 старт для нескольких тысяч любителей спорта. Это больше, чем ЗаБег. Это мировой рекорд.' :
              'Москва встречает участников ЗаБега на своем масштабном и красочном старте.'}
          </p>
          <p>
            {selectedCity?.city?.name_ru !== 'Москва' ?
              'Самый крупный полумарафон с синхронным стартом: 4 дистанции, крутая фан-зона и яркий праздник от Калининграда до Камчатки.'
              : 'Самый крупный полумарафон страны: 4 дистанции, крутая фан-зона и маршрут по самым красивым локациям столицы.'}
          </p>
          <p>
            Спортивный фестиваль в центре твоего города.
          </p>
          <p style={{ color: '#D6356B', fontFamily: 'Panton ExtraBold', fontWeight: '800' }}>
            ЗаБег — это для тебя!
          </p>
        </>}
        list={descriptionMainList}
      />
      <Banner />
      <FormatCity
        title="Страна в одном ритме"
        desc="Докажи, что твой город - лучший!"
        onChangeCity={onChangeCity}
        background={backFormatCity}
      />
      <Formats />
      <Container>
        <FormatBig />
        <Corporate />
      </Container>
      <CityRoute />
      {/*<HowTakePart />*/}
      {/*<TicketCount userCity={city} />*/}
      <StarterPack />
      <Engraving />
      <Emotions />
      <Comfort />
      <RunningCapital />
      {/*<Running />*/}
      <Partners />
      {/*<Discount />*/}
      <Faq2 />
      {/*<Faq selectedCity={selectedCity} />*/}
      <Footer />
      <ImportantInfoPopup />
      {/*{selectedCity?.public_id === 'zabeg2024_498817' && <SportsDayPopup img={img1} />}*/}
      {/*{selectedCity?.public_id === 'zabeg2024_524901' && <SportsDayPopup img={img2} />}*/}
      {/*{selectedCity?.public_id === 'zabeg2024_578072' && <BelPopup />}*/}
      {/*<NextPricePopup formats={formats} />*/}
    </>
  );
};

export default Main;
