import Container from '@/components/App/Container/Container';

import styles from './Emotions.module.scss';


const Emotions = () => {
  return (
    <section className={styles.emotions}>
      <Container>
        <h2 className={styles.title}>Твои эмоции</h2>
        <p className={styles.description}>Участвуй в самом ярком спортивном событии весны!</p>
        <div className={styles.route}>
          <p className={styles.label}>На трассе:</p>
          <ul className={styles.list}>
            <li className={styles.listItem}>Лучшие маршруты в сердце твоего города</li>
            <li className={styles.listItem}>Каждые 2,5 км пункты питания и освежения</li>
            <li className={styles.listItem}>Безопасность и медицинское сопровождение</li>
            <li className={styles.listItem}>Чёткая навигация</li>
          </ul>
          <p>
          Беги со всей страной и получи невероятный заряд эмоций! Делай шаг навстречу своему приключению прямо сейчас!
          </p>
        </div>
        <div className={styles.funZone}>
          <p className={styles.label}>Фан-зона:</p>
          <ul className={`${styles.list} ${styles.funZoneList}`}>
            <li className={styles.listItem}>Яркая и запоминающаяся шоу-программа</li>
            <li className={styles.listItem}>Увлекательные мастер-классы</li>
            <li className={styles.listItem}>Спортивные и детские активности</li>
            <li className={styles.listItem}>Целый день настоящих эмоций</li>
          </ul>
          <p>
            Приходи с друзьями и родными — всех гостей ждет крутая развлекательная программа, а для участников ЗаБега
            мы подготовили много приятных сюрпризов на трассе!
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Emotions;
