import mainLogo from '@/assets/images/main-logo.svg';
import Socials from '@/components/Footer/Socials/Socials';

import styles from './MainMenuPopup.module.scss';

import { useSelector } from 'react-redux';
import { getSelectedCity } from '../../../store/slices/app';


const MainMenuPopup = ({handleStateMenu, handleStateCity}) => {
  const selectedCity = useSelector(getSelectedCity);

  const closeMenu = () => {
    handleStateMenu(false);
  };

  const handleCity = () => {
    handleStateCity(true);
    handleStateMenu(false);
  };

  return (
    <div className={styles.container} onBlur={closeMenu}>
      <div  className={styles.menuTop}>
        <img className={styles.logo} src={mainLogo} width="154" height="73" alt="Логотип Забег.РФ"/>
        <button
          className={styles.btnMenu}
          type="button"
          onClick={closeMenu}
        >
          <span className="visually-hidden">Закрыть меню</span>
        </button>

      </div>
      <div className={styles.userBlock}>
        <div className={styles.userBlockItem}>
         <p className={`${styles.userBlockLabel} ${styles.userBlockLabelCity}`}>Ваш город:</p>
         <button className={styles.city} type="button" onClick={handleCity}>{selectedCity?.city?.name_ru}</button>
        </div>
        <div className={styles.userBlockItem}>
          <p className={`${styles.userBlockLabel} ${styles.userBlockLabelPersonal}`}>Личный кабинет:</p>
          <a className={styles.signIn} href="https://heroleague.ru/myevents" target="_blank" rel="noopener noreferrer">Войти</a>
        </div>
      </div>
      <ul className={styles.anchors}>
        <li><a className={styles.anchor} href="#howTakePart">Принять участие</a></li>
        <li><a className={styles.anchor} href="#formats">Форматы забега</a></li>
        <li><a className={styles.anchor} href="#starterPack">Пакет участника</a></li>
        <li><a className={styles.anchor} href="#faq">FAQ</a></li>
        {/* <li><a className={styles.anchor} href="https://heroleague.ru/corp#corpForm/"  target="_blank" rel="noopener noreferrer">Корпоративное участие</a></li> */}
        <li><a className={styles.anchor} href="https://heroleague.ru/click/9A5FBD10" target="_blank" rel="noopener noreferrer">Тренировки</a></li>
        <li><a className={styles.anchor} href="https://забег.рф/polozhenie2024.pdf" target="_blank" rel="noopener noreferrer">Проект положения</a></li>
        <li><a className={styles.anchor} href="https://забег.рф/soglasie.pdf" target="_blank" rel="noopener noreferrer">Согласие родителей</a></li>
      </ul>
      <div className={styles.bottom}>
        <Socials className={styles.socials} />
        <p>все проекты: <a className={styles.bottomLink} href="https://heroleague.ru/">heroleague.ru</a></p>
      </div>
    </div>
  );
};

export default MainMenuPopup;
