export const alphabetArr = [
  { id: 0, symbol: 'А' },
  { id: 1, symbol: 'Б' },
  { id: 2, symbol: 'В' },
  { id: 3, symbol: 'Г' },
  { id: 4, symbol: 'Д' },
  { id: 5, symbol: 'Е' },
  { id: 6, symbol: 'З' },
  { id: 7, symbol: 'И' },
  { id: 8, symbol: 'Й' },
  { id: 9, symbol: 'К' },
  { id: 10, symbol: 'Л' },
  { id: 11, symbol: 'М' },
  { id: 12, symbol: 'Н' },
  { id: 13, symbol: 'О' },
  { id: 14, symbol: 'П' },
  { id: 15, symbol: 'Р' },
  { id: 16, symbol: 'С' },
  { id: 17, symbol: 'Т' },
  { id: 18, symbol: 'У' },
  { id: 19, symbol: 'Х' },
  { id: 20, symbol: 'Ч' },
  { id: 21, symbol: 'Э' },
  { id: 22, symbol: 'Ю' },
  { id: 23, symbol: 'Я' },
];
