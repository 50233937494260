import { useMediaQuery } from 'react-responsive';
import Popup from '@/components/Popups/Popup/Popup';
import styles from './SizesPopup.module.scss';
import tshirt from '@/assets/images/tshirt-popup@2x.png';
import tshirtMobile from '@/assets/images/tshirt-popup-mobile@2x.png';
import { ReactComponent as MainLogo } from '@/assets/images/main-logo3.svg';

const SizesPopup = ({ isShow, onHandleState }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (!isShow) return null;

  const handleStatePopup = () => {
    onHandleState(false);
  };

  return (
    <Popup handleStatePopup={handleStatePopup} outsideStyles={styles}>
      {isMobile && (
        <MainLogo className={styles.logo} />
      )}
      <div className={styles.imgWrap}>
        <img className={styles.img} src={isMobile ? tshirtMobile : tshirt} width={1042} height={612} alt=""/>
      </div>

      <div className={`${styles.tableWrap} ${styles.tableWrapMargin}`}>
        <p className={styles.label}>Взрослые</p>

        <table className={styles.table}>
          <thead>
            <tr>
              <th />
              <th className={styles.firstTd}>А</th>
              <th className={styles.firstTd}>B</th>
              <th className={styles.firstTd}>C</th>
              <th className={styles.firstTd}>D</th>
              <th className={styles.firstTd}>E</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.firstTd}>xss</td>
              <td className={styles.tdValue}>40</td>
              <td className={styles.tdValue}>44</td>
              <td className={styles.tdValue}>44</td>
              <td className={styles.tdValue}>66</td>
              <td className={styles.tdValue}>19</td>
            </tr>
            <tr>
              <td className={styles.firstTd}>xs</td>
              <td className={styles.tdValue}>42</td>
              <td className={styles.tdValue}>45</td>
              <td className={styles.tdValue}>45</td>
              <td className={styles.tdValue}>66</td>
              <td className={styles.tdValue}>19,5</td>
            </tr>
            <tr>
              <td className={styles.firstTd}>s</td>
              <td className={styles.tdValue}>43</td>
              <td className={styles.tdValue}>46</td>
              <td className={styles.tdValue}>46</td>
              <td className={styles.tdValue}>68</td>
              <td className={styles.tdValue}>20</td>
            </tr>
            <tr>
              <td className={styles.firstTd}>m</td>
              <td className={styles.tdValue}>44</td>
              <td className={styles.tdValue}>48</td>
              <td className={styles.tdValue}>48</td>
              <td className={styles.tdValue}>71</td>
              <td className={styles.tdValue}>21,5</td>
            </tr>
            <tr>
              <td className={styles.firstTd}>l</td>
              <td className={styles.tdValue}>46</td>
              <td className={styles.tdValue}>54</td>
              <td className={styles.tdValue}>54</td>
              <td className={styles.tdValue}>74</td>
              <td className={styles.tdValue}>24</td>
            </tr>
            <tr>
              <td className={styles.firstTd}>xl</td>
              <td className={styles.tdValue}>47</td>
              <td className={styles.tdValue}>60</td>
              <td className={styles.tdValue}>60</td>
              <td className={styles.tdValue}>75</td>
              <td className={styles.tdValue}>25</td>
            </tr>
            <tr>
              <td className={styles.firstTd}>xxl</td>
              <td className={styles.tdValue}>48</td>
              <td className={styles.tdValue}>65</td>
              <td className={styles.tdValue}>65</td>
              <td className={styles.tdValue}>79</td>
              <td className={styles.tdValue}>27</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={styles.tableWrap}>
        <p className={styles.label}>Детские</p>

        <table className={styles.table}>
          <tbody className={styles.tbody}>
            <tr>
              <td className={styles.firstTd}>142</td>
              <td className={styles.tdValue}>28</td>
              <td className={styles.tdValue}>39</td>
              <td className={styles.tdValue}>39</td>
              <td className={styles.tdValue}>46</td>
              <td className={styles.tdValue}>16</td>
            </tr>
            <tr>
              <td className={styles.firstTd}>150</td>
              <td className={styles.tdValue}>34</td>
              <td className={styles.tdValue}>40</td>
              <td className={styles.tdValue}>40</td>
              <td className={styles.tdValue}>52</td>
              <td className={styles.tdValue}>17</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Popup>
  );
};

export default SizesPopup;
