import Faq2 from '@/components/Faq2/Faq2';
import Footer from '@/components/Footer/Footer';
import icon1 from '@/assets/images/feature1.svg';
import icon3 from '@/assets/images/feature3.svg';
import icon4 from '@/assets/images/feature4.svg';
import icon2 from '@/assets/images/feature2@1x.png';
import SlotCost from '@/components/SlotCost/SlotCost';
import backFormatCity from '@/assets/images/cover7@1x.png';
import FormatCity from '@/components/FormatCity/FormatCity';
import YourFriends from '@/components/YourFriends/YourFriends';
import HeaderOnline from '@/components/Header/HeaderOnline/HeaderOnline';
import DescriptionOnline from '@/components/DescriptionOnline/DescriptionOnline';

// import Faq from '@/components/Faq/Faq';
// import { useParams } from 'react-router';
// import Running from '@/components/Running/Running';
// import Partners from '@/components/Partners/Partners';
// import NextPricePopup from '@/components/Popups/NextPrice/NextPricePopup';
// import SportsDayPopup from '@/components/Popups/SportsDayPopup/SportsDayPopup';


const instructionListArr = [
  { id: 0, text: 'Никогда не бегал? Регистрируйся на забег.рф и качай любой беговой трекер'},
  { id: 1, text: 'С 4 по 12 июня: пробеги дистанцию в своем ритме и там, где тебе комфортно'},
  { id: 2, text: 'Загрузи скриншот своей пробежки с трекера в личный кабинет. С этого дня ты — рекордсмен!'},
];

const awardListArr = [
  { id: 0, text: 'Уникальная медаль Онлайн ЗаБега в твою коллекцию'},
  { id: 1, text: 'Результат в итоговом зачете'},
  { id: 2, text: 'Электронный сертификат'},
  { id: 3, text: 'Статус рекордсмена Забег.РФ'},
];

const descriptionOnlineList = [
  {
    id: 0,
    text: 'Хочешь начать бегать? Начни сразу с достижений!',
    icon: icon1,
    width: 161,
    height: 162,
  },
  {
    id: 1,
    text: 'Беги дома, в квартире, на даче и в любой точке мира.',
    icon: icon2,
    width: 171,
    height: 123,
  },
  {
    id: 2,
    text: 'Стань первым на Онлайн ЗаБеге',
    icon: icon3,
    width: 159,
    height: 183,
  },
  {
    id: 3,
    text: 'Беги один или с друзьями: поставь мировой рекорд',
    icon: icon4,
    width: 151,
    height: 150,
  },
];

const Online = ({ onChangeCity }) => {
  return (
    <>
      <HeaderOnline onChangeCity={onChangeCity} />
      <DescriptionOnline
        title={<>12 - 26 мая <br/> выходи на старт в формате онлайн!</>}
        subtitle="VIII Всероссийский полумарафон в формате онлайн"
        desc={<>
          <p>Вся страна и 150 000 участников из нескольких стран!</p>
          <p>Выбери удобную дистанцию: 1 км, 5 км, 10 км или 21,1 км. Выбери подходящее время и погоду — пробеги свой любимый маршрут, попади в общий зачет.</p>
          <p>Стань частью главного полумарафона страны!</p>
        </>}
        list={descriptionOnlineList}
      />
      <FormatCity
        title="Забег, который подстраивается под тебя"
        desc="Твой ритм - твоя победа!"
        onChangeCity={onChangeCity}
        background={backFormatCity}
      />
      <SlotCost
        title="Ты решаешь, какой будет твоя трасса и твоя победа"
        desc="Попробуй свои силы: ощути эмоции тех, кто пересекает финиш и встает на пьедестал почета Забег.РФ. Почувствуй настоящий бег!"
        instructionList={instructionListArr}
        awardList={awardListArr}
        awardDesc="Твоим результатом будет гордиться вся страна!"
        isOnline={true}
      />
      {/*<Partners isOnline={true} />*/}
      {/*<Running />*/}
      <YourFriends />
      <Faq2 />
      {/*<Faq userCity={selectedCity} />*/}
      <Footer />

      {/*<SportsDayPopup />*/}
      {/*<NextPricePopup formats={formats} />*/}
    </>
  );
};

export default Online;
