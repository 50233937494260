import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';

import App from './App';
import './assets/styles/index.scss';
import rootReducer from './store/rootReducer';
import reportWebVitals from './reportWebVitals';

// import { Provider } from 'react-redux';
// import { createAPI } from './api';
// import { applyMiddleware, createStore } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import thunk from 'redux-thunk';
// import reducer from './reducer/reducer';
// import {Operation as InfoOperation} from './reducer/userInfo/userInfo';

const store = configureStore({
  reducer: rootReducer,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback="Loading..." >
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    </BrowserRouter>,
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
