import { useState } from 'react';

import Container from '@/components/App/Container/Container';
import { ReactComponent as IconComfort1 } from '@/assets/images/svg/icon-comfort1.svg';
import { ReactComponent as IconComfort2 } from '@/assets/images/svg/icon-comfort2.svg';
import { ReactComponent as IconComfort3 } from '@/assets/images/svg/icon-comfort3.svg';
import { ReactComponent as IconComfort4 } from '@/assets/images/svg/icon-comfort4.svg';
import { ReactComponent as IconComfort5 } from '@/assets/images/svg/icon-comfort5.svg';

import styles from './Comfort.module.scss';


const comfortItems = [
  {
    id: 0,
    title: 'Камеры хранения',
    description: 'Надежные индивидуальные камеры хранения, в которых ты можешь оставить свои вещи в полной сохранности',
    icon: IconComfort1,
    iconWidth: 84,
    iconHeight: 80,
  },
  {
    id: 1,
    title: 'Напитки на трассе',
    description: 'На протяжении всей трассы участникам доступна вода и освежающие напитки',
    icon: IconComfort2,
    iconWidth: 34,
    iconHeight: 90,
  },
  {
    id: 2,
    title: 'Медпункт',
    description: 'В течение всего мероприятия на трассе и в фан-зоне работает Медпункт, сотрудники которого, в случае необходимости, позаботятся о самочувствии участников и гостей',
    icon: IconComfort3,
    iconWidth: 100,
    iconHeight: 92,
  },
  {
    id: 3,
    title: 'Раздевалки',
    description: 'Отапливаемые мужские и женские раздевалки, в которых ты сможешь подготовиться к забегу и переодеться после финиша',
    icon: IconComfort4,
    iconWidth: 96,
    iconHeight: 89,
  },
  {
    id: 4,
    title: 'Подзарядка',
    description: 'Пит-стопы на трассе с легкими закусками и фруктами, которые помогут тебе зарядиться новыми силами!',
    icon: IconComfort5,
    iconWidth: 77,
    iconHeight: 78,
  },
];

const Comfort = () => {
  const [cardActive, setCardActive] = useState(0);

  return (
    <Container className={styles.container}>
      <h2 className={styles.title}>Твой комфорт</h2>
      <p className={styles.description}>Мы позаботились обо всем, чтобы ничто не отвлекало тебя от самого главного!</p>
      <ul className={styles.list}>
        {comfortItems.map(el => (
          <li className={styles.item} key={el.id}>
            <div className={`${styles.itemWrap} ${cardActive === el.id && styles.itemCardActive}`} onClick={() => setCardActive(el.id)}>
            {/*<div>*/}
              <div className={styles.iconWrap}>
                <el.icon className={styles.icon} width={el.iconWidth} height={el.iconHeight} />
              </div>
              <h3 className={styles.iconTitle}>{el.title}</h3>
              <p className={styles.itemDesc}>{el.description}</p>
            </div>
          </li>
        ))}
      </ul>
      <p className={styles.alert}>
        Набор опций может меняться в зависимости от выбранного города
      </p>
    </Container>
  );
};

export default Comfort;
