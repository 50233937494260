import { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router';

import api from './api';
import Main from './Pages/Main/Main';
import Online from './Pages/Online/Online';
import { isOnlineCity } from './utils/common';
import { getCitiesStore, setCities, setCityAbid, setFormats, setSelectedCity } from './store/slices/app';

const ResultsTable = lazy(() => import('./components/ResultsTable'));


function App() {
  const location = useLocation();
  const cities = useSelector(getCitiesStore);
  const dispatch = useDispatch();

  useEffect(() => {
    getCities();
  }, []);

  const getCities = () => {
    fetch('cities.json')
      .then((r) => r.json())
      .then((r) => {
        if (r?.values?.length > 0) {
          dispatch(setCities(r.values));
          setCity(r.values);
        }
      })
      .catch((err) => console.error(err));

    // api.get('/api/event_city/type/zabeg')
    //   .then((r) => {
    //     if (r?.data?.values?.length > 0) {
    //       dispatch(setCities(r.data.values));
    //       setCity(r.data.values);
    //     }
    //
    //   });
  };

  const getFormats = (publicId) => {
    api.get(`/api/event_format/event/${publicId}?ab=one`)
      .then((r) => {
        if (r?.data?.values?.length > 0) {
          dispatch(setFormats(r.data.values));
          dispatch(setCityAbid(r.data.city.abid));
        }
      });
  };

  const getLocalCity = (cities) => {
    const localCity = localStorage.getItem('city');

    if (localCity) {
      try {
        const parseCity = JSON.parse(localCity);

        if (typeof parseCity === 'object') {
          const findCity = cities.find((item) => item.public_id === localCity.public_id);

          return findCity ? parseCity :localStorage.removeItem('city');
        }
      } catch {
        localStorage.removeItem('city');
      }
    }

    return null;
  };

  const setCity = (cities) => {
    const defaultCity = 'zabeg2024_524901';
    const defaultCityOnline = 'zabeg2024_online_999999_1';
    const moscowId = 'zabeg2024_524901';
    const localCity = getLocalCity(cities);

    if (location.pathname === '/moscow' || location.pathname === '/москва') {
      const findCity = cities.find((item) => item.public_id === moscowId);

      if (findCity) {
        dispatch(setSelectedCity(findCity));
        getFormats(findCity.public_id);
      }

    } else {
      if (localCity) {
        const findCity = cities.find((item) => item.public_id === localCity.public_id);
        console.log('findCity', findCity);

        if (findCity) {
          dispatch(setSelectedCity(findCity));
          getFormats(findCity.public_id);

          if (location.pathname !== '/online' && isOnlineCity(findCity)) {
            window.location.replace('/online');
          } else if (location.pathname === '/online' && !isOnlineCity(findCity)) {
            window.location.replace('/');
          }
        }
      } else {
        api.get('/api/location/detailed')
          .then((r) => {
            const findCity = cities.find((item) => item.city.id === r.data.info.city?.id);

            if (findCity && ((location.pathname !== '/online' && !isOnlineCity(findCity)) || (location.pathname === '/online' && isOnlineCity(findCity)))) {
              dispatch(setSelectedCity(findCity));
              getFormats(findCity.public_id);
            } else {
              let findCity;

              if (location.pathname === '/online') {
                findCity = cities.find((item) => item.public_id === defaultCityOnline);
              } else {
                findCity = cities.find((item) => item.public_id === defaultCity);
              }

              if (findCity) {
                dispatch(setSelectedCity(findCity));
                getFormats(findCity.public_id);
              }
            }
          });
      }
    }
  };

  const handleChangeCity = (cityPublicId) => {
    const findCity = cities.find((item) => item.public_id === cityPublicId);

    if (findCity) {
      dispatch(setSelectedCity(findCity));
      getFormats(cityPublicId);
      localStorage.setItem('city', JSON.stringify(findCity));

      if (location.pathname !== '/online' && isOnlineCity(findCity)) {
        window.location.replace('/online');
      } else if (location.pathname === '/online' && !isOnlineCity(findCity)) {
        window.location.replace('/');
      }
    }
  };

  return (
    <Routes>
      <Route path="/" element={<Main onChangeCity={handleChangeCity} />} />
      <Route path="/moscow" element={<Main onChangeCity={handleChangeCity} />} />
      <Route path="/москва" element={<Main onChangeCity={handleChangeCity} />} />
      <Route path="/online" element={<Online onChangeCity={handleChangeCity} />} />
      <Route path="/results" element={<ResultsTable onChangeCity={handleChangeCity} />} />
    </Routes>
  );
}

export default App;
