import styles from './Corporate.module.scss';


const Corporate = () => {
  return (
    <>
      <div className={styles.corpClient}>
        <h2 className={styles.title}>One Run - международный забег</h2>
        <p className={styles.text}>
          Масштабное международное спортивное событие, которое единовременно будет проходить  в 15 странах-участницах.

          <p>
            Целью полумарафона One Run является популяризация спорта среди населения, укрепление партнерских и дружеских
            отношений, а также культурный обмен между странами.
          </p>
        </p>

        <p className={styles.text}>
          Выбирай страну и присоединяйся к крупнейшему полумарафону!
        </p>

        <a className={styles.link} href="https://onerunglobal.com/" target="_blank" rel="noopener noreferrer">
          Подробнее
        </a>
      </div>

      <div className={styles.corpClient}>
        <h2 className={styles.title}>Корпоративным клиентам</h2>
        <p className={styles.text}>
          Если вы хотите пробежать за свой город вместе и показать всем, что такое командный дух, то заполните форму
          для обратной связи — вы получите своевременный ответ от нашей команды!
        </p>
        {/* href="https://heroleague.ru/corp#corpForm" target="_blank" rel="noopener noreferrer" */}
        <button className={styles.link} disabled>
          Распродано
        </button>
      </div>
    </>
  );
};

export default Corporate;
