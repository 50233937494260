import mainLogo from '@/assets/images/main-logo.svg';
import logoMl from '@/assets/images/logo-ml-white.svg';
import Container from '@/components/App/Container/Container';

import Socials from './Socials/Socials';
import styles from './Footer.module.scss';


const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerWrapper}>
          <div className={styles.development}>
            <p className={styles.developmentText}>Концепция и воплощение:</p>
            <img src={logoMl} width="127" height="26" alt="Логотип Marketing League"/>
          </div>
          <div className={styles.logo}>
            <img className={styles.mainLogo} src={mainLogo} width="72" height="46" alt="Логотип Лиги Героев"/>
            <p className={styles.copyright}>АНО “Гонка Героев” ИНН: 7709445877</p>
          </div>
          <div className={styles.socials}>
            <Socials />
            <div className={styles.linksWrap}>
              <a className={styles.link} href="https://heroleague.ru/api/files/document/offers">Оферта</a>
              {/* <a className={styles.link} href="/zabeg2024.pdf">Положение</a> */}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
