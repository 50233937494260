import Container from '@/components/App/Container/Container';

import VideoPlayer from './VideoPlayer';
import styles from './DescriptionOnline.module.scss';


const DescriptionOnline = ({ title, subtitle, desc, list, video = true }) => {
  return (
    <Container className={styles.container}>
      <ul className={styles.list}>
        {list.map(el => (
          <li className={`${styles.listItem}`} key={el.id}>
            <div className={styles.iconWrap}>
              <img className={styles.listItemIcon} src={el.icon} width={el.width} height={el.height} alt={el.text} />
            </div>
            {el.text}
          </li>
        ))}
        {/*<li className={styles.listItem}></li>*/}
        {/*<li className={`${styles.listItem} ${styles.listItem2}`}></li>*/}
        {/*<li className={`${styles.listItem} ${styles.listItem3}`}></li>*/}
        {/*<li className={`${styles.listItem} ${styles.listItem4}`}></li>*/}
      </ul>
      <div className={styles.columnRight}>
        <div className={styles.columnRightWrap}>
          <div className={styles.columnRightTop}>
            <h2 className={styles.title}>{title}</h2>
            <h3 className={styles.subtitle}>{subtitle}</h3>
            <div className={styles.text}>{desc}</div>
          </div>
          <VideoPlayer video="https://heroleague.ru/video/zabeg2023.mp4" />
        </div>
        {video && <a className={styles.link} href="#formats">Принять участие</a>}
      </div>
    </Container>
  );
};

export default DescriptionOnline;
