import { ReactComponent as VkIcon } from '@/assets/images/svg/icon-vk.svg';
import { ReactComponent as YoutubeIcon } from '@/assets/images/svg/icon-youtube.svg';

import styles from './Socials.module.scss';


const socialsArr = [
  { id: 1, icon: VkIcon, label: 'Вконтакте', link: 'https://vk.com/zabegrf/' },
  { id: 2, icon: YoutubeIcon, label: 'Ютуб', link: 'https://www.youtube.com/channel/UCN8vRARh9HeGBJryD41kCwA/' },
];

const Socials = ({className}) => {
  return (
    <ul className={`${styles.list} ${className}`}>
      {socialsArr.map(item => (
        <li className={styles.item} key={item.id}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <item.icon />
            <span className="visually-hidden">{item.label}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Socials;
