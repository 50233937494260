import styles from './RunningCapital.module.scss';
import Container from '../App/Container/Container';

const RunningCapital = () => {
  return (
    <section className={styles.runningCapital}>
      <Container>
        <h2 className={styles.title}>Беговая столица</h2>
        <p className={styles.tagline}>Твой город — твоя гордость!</p>
        <p className={styles.text}>
          Докажи, что он лучший — пусть <br/> твое участие принесет ему звание <br/> Беговой столицы России!
        </p>
        <ul className={styles.citiesContainer}>
          <li className={styles.city}>
            <p className={styles.city_name}>Казань</p>
            <p className={styles.city_year}>2017</p>
            <p className={styles.city_year}>2018</p>
          </li>
          <li className={styles.city}>
            <p className={styles.city_name}>Владивосток</p>
            <p className={styles.city_year}>2019</p>
          </li>
          <li className={styles.city}>
            <p className={styles.city_name}>Нижний Новгород</p>
            <p className={styles.city_year}>2020</p>
            <p className={styles.city_year}>2022</p>
          </li>
          <li className={styles.city}>
            <p className={styles.city_name}>Санкт-Петербург</p>
            <p className={styles.city_year}>2021</p>
          </li>
          <li className={styles.city}>
            <p className={styles.city_name}>Липецк</p>
            <p className={styles.city_year}>2023</p>
          </li>
        </ul>
        <div className={styles.bottom}>
          <p className={styles.appeal}>Беги за свой город!</p>
          <a className={styles.link} href="#formats">Принять участие</a>
          <p className={styles.description}>
            При расчёте «Беговой столицы» учитываются только 4 дистанции 1 км, «ПСБ 5 км», 10 км, 21,1 км
          </p>
        </div>
      </Container>
    </section>
  );
};

export default RunningCapital;
