import { useSelector } from 'react-redux';

import { getSelectedCity } from '@/store/slices/app';

import styles from './CityRoute.module.scss';


const CityRoute = () => {
  const selectedCity = useSelector(getSelectedCity);

  if (!selectedCity || !selectedCity?.info?.map_preview) return null;

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Маршрут твоего города</h2>
      <div className={styles.notification}>
        <a href={selectedCity.info.map_full} target="_blank" rel="noopener noreferrer">
          <img className={styles.map} src={selectedCity.info.map_preview} alt="Карта маршрутов"/>
        </a>
      </div>
    </div>
  );
};

export default CityRoute;
