import { useState } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { getFormatsStore, getSelectedCity } from '@/store/slices/app';

import styles from './ImportantInfoPopup.module.scss';


const ImportantInfoPopup = () => {
  const formats = useSelector(getFormatsStore);
  const selectedCity = useSelector(getSelectedCity);

  const [isShow, setShow] = useState(false);

  const getFormatTime = (format) => {
    const time = moment.tz(format.start_time * 1000, format.timezone);
    return `${format.title} — ${time.format('HH:mm')}`;
  };

  if (!formats) return null;

  return (
    <section className={`${styles.wrapper} ${isShow && styles.wrapperActive}`}>
      <div className={styles.container}>
        <h2 className={`${styles.title} ${isShow && styles.titleActive}`} onClick={() => setShow(!isShow)}>
          Важная информация
        </h2>
        <div className={`${styles.content} ${isShow && styles.contentActive}`}>
          <p className={styles.addressTitle}>Адрес старта</p>
          <p className={styles.address}>{selectedCity?.info?.start_address || 'Информация появится позже'}</p>
          <div className={styles.bottom}>
            <div className={styles.timesWrap}>
              <p className={styles.label}>Время стартов</p>
              <ul className={styles.times}>
                {formats && selectedCity && formats.map((format) => (
                  <li className={styles.time} key={format.public_id}>{getFormatTime(format)}</li>
                )) }
              </ul>
              <p className={`${styles.address} ${styles.addressTime}`}>Время местное</p>
            </div>
            <div className={styles.starterPackWrap}>
              <p className={styles.label}>Выдача стартовых пакетов</p>
              <p className={styles.address}>{selectedCity?.info?.registration_address || 'Информация появится позже'}
                  <br />{selectedCity?.info?.registration_time || ''}
              </p>
              <div className={styles.importantButtons}>
                {selectedCity?.info?.legals && (
                  <a href={selectedCity.info.legals} className={styles.button} type="button">Положение</a>
                )}
              </div>
            </div>
          </div>

          <button
            className={styles.closeBtn}
            type="button"
            onClick={() => setShow(false)}
          >
            <span className="visually-hidden">Скрыть</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ImportantInfoPopup;
