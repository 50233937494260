import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedCity } from '@/store/slices/app';
import Container from '@/components/App/Container/Container';
import ChoiceCityPopup from '@/components/Popups/ChoiceCity/ChoiceCityPopup';

import styles from './FormatCity.module.scss';


const FormatCity = ({title, desc, onChangeCity, background}) => {
  const selectedCity = useSelector(getSelectedCity);
  const [isOpenCityModal, setOpenCityModal] = useState(false);

  const onClickChoiceCity = () => {
    setOpenCityModal(true);
  };

  return (
    <>
      <section className={styles.formatCity} style={{backgroundImage: `url("${background}")`}}>
        <Container className={styles.container}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.desc}>{desc}</p>
          <p className={styles.city}>{selectedCity?.city?.name_ru !== 'Другой город' ? selectedCity?.city?.name_ru : 'Твой онлайн забег'}</p>
          {/*<button className={styles.btn} onClick={onClickChoiceCity}>Другой город</button>*/}
        </Container>
      </section>

      {isOpenCityModal && <ChoiceCityPopup handleStateCity={setOpenCityModal} onChangeCity={onChangeCity} />}
    </>
  );
};

export default FormatCity;
