import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector } from 'react-redux';

import formatPerson1n from '@/assets/images/format-person1.svg';
import formatPerson2n from '@/assets/images/format-person2.svg';
import formatPerson3n from '@/assets/images/format-person3.svg';
import formatPerson4n from '@/assets/images/format-person4.svg';
import { getCurrentPrice, isRegClose } from '@/utils/common';
import { getFormatsStore, getSelectedCity } from '@/store/slices/app';
import FormatBuyPopup from '@/components/Popups/FormatBuy/FormatBuyPopup';

import styles from './Formats.module.scss';
import PriceSteps from './PriceSteps/PriceSteps';


const formatsFront = [
  {
    id: 0,
    titleHeading: 'Юниор',
    desc: 'Каждый из нас с детства мечтает о победах',
    distance: '1',
    appeal: 'Сделай первый шаг',
    titlePanel: 'Каждый из нас с детства мечтает о победах',
    descPanel: 'Еще бы, ведь это тот самый опыт, который создает внутренний стержень и настрой на успех в будущем. Счастье первой спортивной победы - отличный подарок! Первая победа - победитель навсегда!',
    price: null,
    prices: [
      {
        price: 612,
        start_date: 1622419200,
        end_date: 1653715800,
      },
      {
        price: 715,
        start_date: 1622419200,
        end_date: 1653715800,
      },
      {
        price: 817,
        start_date: 1622419200,
        end_date: 1653715800,
      },
    ],
    img: formatPerson1n, imgWidth: 179, imgHeight: 371,
  },
  {
    id: 1,
    titleHeading: 'Новичок',
    desc: 'Все большие свершения начинаются с малого',
    distance: 'ПСБ 5',
    appeal: 'Проверь себя',
    titlePanel: 'Все большие свершения начинаются с малого',
    descPanel: 'Пробеги ПСБ 5 км и почувствуй новый уровень уверенности в своих силах. Преодолей дистанцию один или в компании друзей, получи свою первую медаль и стань частью Лиги Героев. Все получится!',
    price: null,
    prices: [
      {
        price: 612,
        start_date: 1622419200,
        end_date: 1653715800,
      },
      {
        price: 715,
        start_date: 1622419200,
        end_date: 1653715800,
      },
      {
        price: 817,
        start_date: 1622419200,
        end_date: 1653715800,
      },
    ],
    img: formatPerson2n, imgWidth: 179, imgHeight: 371,
  },
  {
    id: 2,
    titleHeading: 'Любитель',
    desc: 'Красочные локации и соперничество с лучшими из лучших',
    distance: '10',
    appeal: 'Прояви себя',
    titlePanel: 'Красочные локации и соперничество с лучшими из лучших',
    descPanel: 'ЗаБег – это яркое спортивное приключение и повод для того, чтобы поддерживать себя в отличной форме круглый год и, конечно, рассказывать об этом с гордостью.',
    price: null,
    prices: [
      {
        price: 612,
        start_date: 1622419200,
        end_date: 1653715800,
      },
      {
        price: 715,
        start_date: 1622419200,
        end_date: 1653715800,
      },
      {
        price: 817,
        start_date: 1622419200,
        end_date: 1653715800,
      },
    ],
    img: formatPerson3n, imgWidth: 179, imgHeight: 371,
  },
  {
    id: 3,
    titleHeading: 'Профи',
    desc: 'Вызов, подтверждение статуса и пример новичкам',
    distance: '21,1',
    appeal: 'Отбрось сомнения',
    titlePanel: 'Вызов, подтверждение статуса и пример новичкам',
    descPanel: 'ЗаБег – профессиональное превосходство на своей территории и запоминающийся старт сезона твоих побед. Открой его вместе с нами',
    price: null,
    prices: [
      {
        price: 612,
        start_date: 1622419200,
        end_date: 1653715800,
      },
      {
        price: 715,
        start_date: 1622419200,
        end_date: 1653715800,
      },
      {
        price: 817,
        start_date: 1622419200,
        end_date: 1653715800,
      },
    ],
    img: formatPerson4n, imgWidth: 179, imgHeight: 371,
  },
];

const Formats = () => {
  const selectedCity = useSelector(getSelectedCity);
  const formatsCity = useSelector(getFormatsStore);
  const [acState, setAcState] = useState(false);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [format, setFormat] = useState(null);

  const uniteFormats = () => {
    if (formatsCity !== null && formatsCity.length !== 0) {
      const filteredFormatsCity = formatsCity.filter((item) => item.public_id !== 'zabeg2024_498817_zaezd');

      return formatsFront.map((el, index) => Object.assign(el, filteredFormatsCity[index]));
    } else {
      return formatsFront;
    }
  };

  const formats = uniteFormats();

  const updatePriceSteps = () => {
    setAcState(!acState);
  };

  const handleBuy = (item) => {
    setOpenPopup(true);
    setFormat(item);
  };

  if (selectedCity && isRegClose(selectedCity)) return null;

  return (
    <div className={styles.formats} id="formats">
      <Accordion className={styles.accordion} preExpanded={['0']}>
        {formats.map(el => (
          <AccordionItem activeClassName={styles.accordionItemActive} uuid={`${el.id}`} onClick={updatePriceSteps} key={el.id}>
            <AccordionItemHeading className={styles.heading}>
              <AccordionItemButton>
                <div className={styles.headingTop}>
                  <h2 className={styles.title}>{el.titleHeading}</h2>
                  <p  className={styles.headingDesc}>{el.desc}</p>
                  <p className={styles.headingDistance}>
                    <span className={styles.headingNumber}>{el.distance}</span> <br/> км
                  </p>
                </div>
                <p className={styles.headingBottom}>
                  {el.appeal}
                  <span className={styles.headingToggle} />
                </p>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordionPanel}>
              <div className={styles.panelWrapper}>
                <div className={styles.personFigure}>
                  <img src={el.img} width={el.imgWidth} height={el.imgHeight} alt={el.title}/>
                </div>
                <div className={styles.panelContent}>
                  <div className={styles.columnLeft}>
                    <h3 className={styles.panelTitle}>{el.titlePanel}</h3>
                    <p className={styles.panelText}>{el.descPanel}</p>

                    {el.price !== null ?
                      <>
                        <div className={styles.currentCostWrap}>
                          { el.prices.length > 1 ?
                            <>{acState || !acState ? <PriceSteps priceArr={el.prices} currentPrice={el.price} /> : '' }</>
                            :
                            <span className={styles.currentCost}>{el.price}&#8381;</span>
                          }

                        </div>
                        {getCurrentPrice(el.prices) && <div className={styles.nextCostWrap}>
                        {/*<span className={styles.currentCostText}>Следующая цена с&ensp;*/}
                        {/*  {times.getNumberDate(getCurrentPrice(el.prices).start_date)}:*/}
                        {/*</span>*/}
                        {/*  <span className={styles.nextCost}>{getCurrentPrice(el.prices).price}&#8381;</span>*/}
                        </div>}

                        {/**/}
                      </>
                      :
                      <>Выберите город</>
                    }

                  </div>
                  <div className={styles.exampleRoute}>
                    {el.price !== null  &&
                      <>
                        {/*<Link className={styles.linkExample} to={'/'}>Пример маршрута</Link>*/}
                        <button
                          className={styles.linkBuy}
                          onClick={() => handleBuy(el)}
                          disabled={el.tickets_left.athlete < 1}
                        >
                          {el.tickets_left.athlete < 1 ? (
                            'Распродано'
                          ): (
                            'Купить'
                          )}
                        </button>
                      </>
                    }
                  </div>
                  <div className={styles.medCertificate}>
                    <p>
                      Для участия в ЗаБеге необходимо получить медицинскую справку это просто
                    </p>
                    <a
                      className={styles.medLink}
                      href="https://забег.рф/spravka.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Подробнее</a>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {/*<AccordionItem activeClassName={styles.accordionItemActive} uuid={'5'}>*/}
        {/*  <AccordionItemHeading className={styles.headingWatch}>*/}
        {/*    <AccordionItemButton>*/}
        {/*      <img className={styles.watchImg} src={watchImg} alt=""/>*/}
        {/*      <p className={styles.bottomWatch}>*/}
        {/*        Будь свободным*/}
        {/*        <span className={styles.headingToggle} />*/}
        {/*      </p>*/}
        {/*    </AccordionItemButton>*/}
        {/*  </AccordionItemHeading>*/}

        {/*  <AccordionItemPanel  className={`${styles.accordionPanel} ${styles.accordionPanelOnline}`}>*/}
        {/*    <div className={styles.panelWrapper}>*/}
        {/*      <div className={`${styles.panelContent} ${styles.panelContentOnline}`}>*/}
        {/*        <div className={`${styles.columnLeft} ${styles.columnLeftOnline}`}>*/}
        {/*          <h3 className={styles.panelTitle}>Переверни своё представление о забеге! Зарегистрируйся и скачай любой беговой трекер.</h3>*/}
        {/*          <p className={`${styles.panelText} ${styles.panelOnlineText}`}>*/}
        {/*            Забег 2021 - Не важно где ты будешь. Все, что тебе нужно, – это подтверждение участия и любой*/}
        {/*            беговой трекер. Ты сможешь бежать по собственной трассе в удобное время, но с поддержкой всей*/}
        {/*            страны и достойной наградой на финише!*/}
        {/*          </p>*/}
        {/*          <p className={styles.panelOnlinePrice}>*/}
        {/*            Стоимость билета: <span className={styles.panelOnlinePriceWrap}>600 Р</span>*/}
        {/*          </p>*/}
        {/*        </div>*/}
        {/*        <div className={styles.onlineRoute}>*/}

        {/*          /!*1х: 503px; 2x: 1006px*!/*/}
        {/*          <img className={styles.onlineRouteImg} src={onlineRouteImg1x} srcSet={`${onlineRouteImg2x} 2x`} alt="Маршрут"/>*/}
        {/*        </div>*/}
        {/*        <div className={styles.onlineRegistration}>*/}
        {/*          <p className={styles.onlineRegText}>Беги Онлайн <br/> на ЗаБеге 2022!</p>*/}
        {/*          <a className={styles.linkBuy} href={process.env.REACT_APP_API + `/calendar/zabeg2024/999999`} target="_blank" rel="noopener noreferrer">Регистрация</a>*/}
        {/*        </div>*/}

        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </AccordionItemPanel>*/}
        {/*</AccordionItem>*/}

      </Accordion>

      { isOpenPopup && <FormatBuyPopup handleStatePopup={setOpenPopup} format={format} /> }
    </div>
  );
};

export default Formats;
