import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import mainLogo from '@/assets/images/main-logo.svg';
import oneRunLogo from '@/assets/images/logo-one-run.svg';
import { calculateTimeLeft } from '@/customHooks/useTimer';
import { getFormatsStore, getSelectedCity } from '@/store/slices/app';
import { getRightNames, isRegClose, getResultsLink, times } from '@/utils/common';

import logoPsb from '@/assets/images/partners/logo-psb.svg';

import Header from '../Header';
import styles from './HeaderMain.module.scss';
import Popup from '@/components/Popups/Popup/Popup';


const HeaderMain = ({onChangeCity}) => {
  const formats = useSelector(getFormatsStore);
  const selectedCity = useSelector(getSelectedCity);
  const [date, setDate] = useState(1716109800);
  const [untilDate, setUntilDate] = useState(times.getNumberDate(date));
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(untilDate));
  const [isOpenPartnerModal, setIsOpenPartnerModal] = useState(false);

  useEffect(() => {
    if (formats && formats.length > 0) {
      setDate(formats[0].start_time);
      setUntilDate(times.getNumberDate(formats[0].start_time));
    }

    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(untilDate));
    }, 1000);
    return () => clearTimeout(timer);
  }, [date, untilDate, formats]);

  const handleOpenPartnerModal = (evt) => {
    setIsOpenPartnerModal(true);
    evt.preventDefault();
  };

  return (
    <Header className={styles.header} onChangeCity={onChangeCity}>
      <a className={styles.partnerLink} href="https://www.psbank.ru/" target="_blank" rel="noopener noreferrer">
       <span className={styles.partnerLabel}>Генеральный спонсор</span>
       <img className={styles.partnerLogo} src={logoPsb} width="257" height="153" alt="Спонсор ПСБ"/>

        <button className={styles.partnerBtn} onClick={handleOpenPartnerModal}>
          <span className="visually-hidden">Дополнительная информация</span>
        </button>
      </a>
      <a className={`${styles.corpLink} ${styles.trainingLink}`} href="https://heroleague.ru/click/9A5FBD10" target="_blank" rel="noopener noreferrer">
        Тренировки
      </a>
      {/* <a className={styles.corpLink} href="https://heroleague.ru/corp#corpForm/" target="_blank" rel="noopener noreferrer">
        Корпоративное участие
      </a> */}
      <a className={styles.corpLink} href="https://xn--80acghh.xn--p1ai/online" target="_blank" rel="noopener noreferrer">
        Забег Online
      </a>
      <div className={styles.content}>
        <div className={styles.logoWrap}>
          <img className={styles.logo} src={mainLogo} width="154" height="73" alt="Логотип Забег.РФ"/>
          <img className={`${styles.logo} ${styles.oneRunLogo}`} src={oneRunLogo} width="142" height="78" alt="Логотип OneRun"/>
        </div>
        <h1 className={styles.title}>
          Заряжай бегом
        </h1>
        <div className={styles.bottom}>
          {Object.keys(selectedCity)?.length > 0 && (
            <a className={`${styles.btn} ${(isRegClose(selectedCity) && !getResultsLink(selectedCity)) ? styles.btnRegClose : ''}`} href={getResultsLink(selectedCity) ?? '#formats'}>
              { isRegClose(selectedCity) ? 'Продажи закрыты' : 'Купить билет'}
            </a>
          )}
          {/*<a className={styles.btn} href="https://heroleague.ru/results" target="_blank" rel="noopener">Результаты</a>*/}
          <span className={styles.timer}>
            <span className={styles.numWrap}>
              <span className={styles.number}>{timeLeft.days}</span>
              <span className={styles.labelNum}>{getRightNames(timeLeft.days, 'день', 'дня', 'дней')}</span>
            </span>

            <span className={styles.numWrap}>
              <span className={styles.number}>{timeLeft.hours}</span>
              <span className={styles.labelNum}>{getRightNames(timeLeft.hours, 'час', 'часа', 'часов')}</span>
            </span>

            <span className={styles.numWrap}>
              <span className={styles.number}>{timeLeft.minutes}</span>
              <span className={styles.labelNum}>{getRightNames(timeLeft.minutes, 'минута', 'минуты', 'минут')}</span>
            </span>
          </span>
        </div>
      </div>

      {isOpenPartnerModal && (
        <Popup outsideStyles={styles} handleStatePopup={setIsOpenPartnerModal}>
          <p>ПАО "Промсвязьбанк"</p>
          <p>ИНН: 7744000912</p>
          <p>ERID: 2VtzqvH4CeV</p>
        </Popup>
      )}
    </Header>
  );
};

export default HeaderMain;
