import { createSlice } from '@reduxjs/toolkit';

import NameSpace from '../nameSpace';


export const appSlice = createSlice({
  name: NameSpace.APP,
  initialState: {
    cities: [],
    formats: [],
    selectedCity: {},
    abid: '',
  },
  reducers: {
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setFormats: (state, action) => {
      state.formats = action.payload;
    },
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
    setCityAbid: (state, action) => {
      state.abid = action.payload;
    },
  },
});

export const { setCities, setSelectedCity, setFormats, setCityAbid } = appSlice.actions;

export const getCitiesStore = (state) => state[NameSpace.APP].cities;
export const getFormatsStore = (state) => state[NameSpace.APP].formats;
export const getSelectedCity = (state) => state[NameSpace.APP].selectedCity;
export const getCityAbid = (state) => state[NameSpace.APP].abid;

export default appSlice.reducer;
