import logo from '@/assets/images/main-logo.svg';

import Header from '../Header';
import styles from './HeaderOnline.module.scss';


const HeaderOnline = ({onChangeCity}) => {
  return (
    <Header className={styles.header} onChangeCity={onChangeCity}>
      {/*<a href="https://www.psbank.ru/" target="_blank" rel="noopener noreferrer">
        <img className={styles.partnerLogo} src={logoPsb} width="257" height="153" alt="Партнёр ПСБ"/>
      </a>*/}
      <div className={styles.wrapper}>
        <p className={styles.text}>Онлайн</p>
        <img className={styles.logo} src={logo} width="598" height="293" alt="Онлайн ЗаБег"/>
      </div>
    </Header>
  );
};

export default HeaderOnline;
